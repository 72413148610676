import { Card, Typography } from '@mui/material'
import { Plan } from '../../api/models/model_product_detail'
import CopyButton from '../../components/common/copy_text/copy_text'
import Spacer from '../../components/common/util/spacer'

interface PlanDetailPageProps {
    plan: Plan
}
export default function PlanDetailPage({ plan }: PlanDetailPageProps) {
    return (
        <div>
            <div>
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 'w200',
                    }}
                >
                    Plan
                </Typography>
                <Spacer height={5} />
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 'bold',
                        fontSize: '22px',
                        color: '#2257BE',
                    }}
                >
                    {plan.name}
                </Typography>
            </div>
            <Card sx={{ padding: 3, width: 1000 }}>
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 'bold',
                    }}
                >
                    Plan details
                </Typography>
                <Spacer height={3} />
                <CopyButton textToCopy={plan.slug} />
                <Spacer height={10} />
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 'w300',
                    }}
                >
                    Version : {plan.version}
                </Typography>
                <Spacer height={10} />
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 'w300',
                    }}
                >
                    Description : {plan.description}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 'w300',
                    }}
                >
                    Product : {plan.product_id}
                </Typography>
            </Card>
        </div>
    )
}

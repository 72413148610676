import { getClient } from '../configs/axios_configs'
import { defineCancelApiObject } from '../configs/axios_utils'
import { ModelSubscription } from '../models/model_subscription'

export const SubscriptionAPI = {
    getCustomerSubscriptions: async function (cancel = false, customer_id: string) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.request({
            method: 'GET',
            url: `/gw/subscriptions/customer/${customer_id}`,
            headers: {
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.getCustomerSubscriptions.name].handleRequestCancellation().signal
                : undefined,
        })
        if (response.data['data'] === null) return []
        const data: ModelSubscription[] = response.data['data'].map(
            (item: any) =>
                ({
                    ...item,
                } as ModelSubscription)
        )
        return data
    },
}

const cancelApiObject = defineCancelApiObject(SubscriptionAPI)

import { ToastStatus } from '@/utils/enums'
import React from 'react'
import toast, { Toast } from 'react-hot-toast'

interface ToastProps {
    t: Toast
    message: string
    success?: boolean
    error?: boolean
    loading?: boolean
}

const FlexToast: React.FC<ToastProps> = ({ t, message, success, error, loading }) => {
    const getStatusIcon = () => {
        if (loading)
            return (
                <span role="img" aria-label="loading">
                    ⏳
                </span>
            )
        if (success)
            return (
                <span role="img" aria-label="success">
                    ✅
                </span>
            )
        if (error)
            return (
                <span role="img" aria-label="error">
                    ❌
                </span>
            )
        return (
            <span role="img" aria-label="info">
                ℹ️
            </span>
        )
    }

    return (
        <div
            className={`toast-container ${t.visible ? 'show' : ''}`}
            style={{
                padding: '16px',
                background: '#333',
                color: '#fff',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.2)',
            }}
        >
            {getStatusIcon()}
            <span>{message}</span>
        </div>
    )
}

export const showCustomToast = (message: string, toastStatus: ToastStatus) => {
    return toast.custom((t: Toast) => (
        <FlexToast
            t={t}
            message={message}
            success={toastStatus === ToastStatus.SUCCESS}
            error={toastStatus === ToastStatus.ERROR}
            loading={toastStatus === ToastStatus.LOADING}
        />
    ))
}

export const showDefaultToast = (message: string) => {
    return toast(message, {
        duration: 4000,
        style: {
            background: '#333',
            color: '#fff',
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0,0,0,0.2)',
        },
    })
}

export default FlexToast

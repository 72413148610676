import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Card,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    IconButton,
    InputAdornment,
    MenuItem,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import Spacer from '../../components/common/util/spacer'
import FlexColors from '../../utils/colors'
import { useEffect, useState } from 'react'
import { ModelProduct } from '../../api/models/model_product'
import FlexDropdown from '../../components/common/dropdown/dropdown'
import { ProductAPI } from '../../api/req/product_api'
import { Add, BorderColor, Close, ExpandMore, Loyalty } from '@mui/icons-material'
import FlexButton from '../../components/common/button/button'
import AddFeatureButton from './AddFeatureButton'
import FeatureTable from './FeatureTable'
import { Feature, FeatureMapping } from '../../api/models/model_product_detail'
import { FeatureAPI } from '../../api/req/feature_api'
import { CustomerAPI } from '../../api/req/customer_api'
import { ModelQuotation, ModelQuotationResponse } from '../../api/models/model_quotation'
import { AxiosError } from 'axios'
import Quotation from './Quotation'
import { IOSSwitch } from '../../components/common/switch/flex_switch_ios'
import { ModelGuardRail } from '../../api/models/model_guardrail'
import { exportMultipleQuotationToExcel, validateEffectValue } from '../../utils/utility_functions'
import LikeDislikeButton from './LikeDislikeButton'
import QuotationCard from './QuotationCard'
import { ProductDetailAPI } from '@/api/req/product_detail_api'
import CustomerDropdown from './CustomerDropdown'
import FlexTextField from '@/components/common/textfield/textfield'
import { SearchIcon } from 'lucide-react'
import FlexSelect from '@/@ui-lib/atoms/Select/Select'

export interface FormModel {
    customerName: string
    customerEmail: string
    searchFeatureText: string
    feature: ModelFeature[]
}

export interface ModelSnackbar {
    title: string
    isError: boolean
}

export interface ModelFeature {
    id: string
    featureName: string
    slug: string
    isRequested: boolean
    featureType: string
    requestedQuantity: number
    configOptions: string[]
    selectedConfigOption: string
}

export default function SalesPageNew() {
    // STATE VARIABLES
    const [products, setProducts] = useState<ModelProduct[]>()
    const [selectedProduct, setSelectedProduct] = useState<ModelProduct>()

    // Radio button for customer
    const [selectedCustomer, setSelectedCustomer] = useState<{
        id: string
        name: string
        email: string
    }>()

    const [selectedFeature, setSelectedFeature] = useState<ModelFeature[]>([])
    const [featureList, setFeatureList] = useState<Feature[]>([])
    const [featureMapping, setFeatureMapping] = useState<FeatureMapping[]>([])

    // Dialog actions
    const [openFeatureList, setOpenFeatureList] = useState(false)
    const [openDiscountBox, setOpenDiscountBox] = useState(false)
    const [productGuardRail, setProductGuardRail] = useState<ModelGuardRail[]>()

    const [formValue, setFormValue] = useState<FormModel>({
        customerName: '',
        customerEmail: '',
        searchFeatureText: '',
        feature: [],
    })

    // Discount
    const [enableDiscount, setEnableDiscount] = useState(false)
    const [discountFixed, setDiscountFixed] = useState(0)
    const [discountRecurring, setDiscountRecurring] = useState(0)
    const [errorFixed, setErrorFixed] = useState(false)
    const [errorRecurring, setErrorRecurring] = useState(false)

    // QUOATATIONS
    const [quotations, setQuotation] = useState<ModelQuotation[] | null>(null)
    const [submittingRequirements, setSubmittingRequirements] = useState(false)
    const [quotationResponse, setQuotationResponse] = useState<ModelQuotationResponse | null>(null)
    const [expandQuotations, setExpandQuotation] = useState(false)
    const [billinPeriod, setBillingPeriod] = useState<string>('Monthly')

    // Saved Quotation
    const [savedQuotation, setSavedQuotation] = useState<ModelQuotation[]>([])
    const [loadingSaveQuotation, setLoadingSaveQuotation] = useState(false)
    const [requirements, setRequirements] = useState<{ [key: string]: any }>()
    // SNACK BAR
    const [alertOpen, setAlertOpen] = useState(false)
    const [snackBarInfo, setSnackBarInfo] = useState<ModelSnackbar>({
        title: 'Alert MSG',
        isError: false,
    })
    // loaders

    const [loadingFeatures, setLoadingFeatures] = useState(false)

    const [collapseDetails, setCollapseDetails] = useState(true)
    const [collapseFeturesSelection, setCollapseFeturesSelection] = useState(true)

    const [selectedQuotation, setSelectedQuotation] = useState<ModelQuotation>()

    const [featureSearchText, setFeatureSearchText] = useState('')
    // FUNCTIONS
    const handleClose = () => {
        setOpenFeatureList(false)
    }

    const handleFeatureSearch = (event: string) => {
        setFeatureSearchText(event)
    }

    const handleFeatureSelect = (slug: string) => {
        const feature = featureList.find((f) => f.slug === slug)
        if (feature && !selectedFeature.some((f) => f.slug === slug)) {
            setSelectedFeature((prevFeatures) => [
                {
                    id: feature.id,
                    featureName: feature.name,
                    slug: feature.slug,
                    isRequested: true,
                    requestedQuantity:
                        featureMapping.find((item) => item.feature_id === feature.id)?.configuration.usage_limit ?? 1,
                    featureType: feature.type,
                    configOptions: feature.config_options || [],
                    selectedConfigOption: feature.config_options !== null ? feature.config_options[0] : '',
                },
                ...prevFeatures,
            ])
        }
    }

    const handleQuantityChange = (slug: string, value: string) => {
        setSelectedFeature((prevFeatures) =>
            prevFeatures.map((feature) =>
                feature.slug === slug ? { ...feature, requestedQuantity: Number(value) } : feature
            )
        )
    }

    const handleConfigOptionChange = (slug: string, value: string) => {
        setSelectedFeature((prevFeatures) =>
            prevFeatures.map((feature) =>
                feature.slug === slug ? { ...feature, selectedConfigOption: value } : feature
            )
        )
    }

    const handleDeleteFeature = (slug: string) => {
        setSelectedFeature((prevFeatures) => prevFeatures.filter((feature) => feature.slug !== slug))
    }

    const onProductSelected = (product: ModelProduct) => {
        let featureMapping: FeatureMapping[] | undefined = []
        setLoadingFeatures(true)
        ProductDetailAPI.getAll(product.id).then((productDetail) => {
            featureMapping = productDetail.plans[0].feature_mappings
            setFeatureMapping(featureMapping ?? [])
        })
        FeatureAPI.getAll(false, product!.slug!).then((features) => {
            setFeatureList(features)
            if (features) {
                if (features.length === 0) {
                    setLoadingFeatures(false)
                    setSelectedFeature([])
                } else {
                    const featureList = features.slice(0, 5)
                    setSelectedFeature(
                        featureList.map((feature) => ({
                            id: feature.id,
                            featureName: feature.name,
                            slug: feature.slug,
                            isRequested: true,
                            requestedQuantity:
                                featureMapping?.find((item) => item.feature_id === feature.id)?.configuration
                                    .usage_limit ?? 1,
                            featureType: feature.type,
                            configOptions: feature.config_options || [],
                            selectedConfigOption: feature.config_options !== null ? feature.config_options[0] : '',
                        })) as ModelFeature[]
                    )
                    setLoadingFeatures(false)
                }
            } else {
                setSelectedFeature([])

                setLoadingFeatures(false)
            }
        })

        ProductAPI.getProductGuardRail(product.id).then((guardRail) => {
            setProductGuardRail(guardRail)
        })
    }
    const getCustomerQuotes = (customerId: string) => {
        setLoadingSaveQuotation(true)
        CustomerAPI.getCustomerQuotation(false, customerId)
            .then((quotations) => {
                setSavedQuotation(quotations)
                setSnackBarInfo({
                    title: quotations.length > 0 ? 'Quotation retrieved successfully' : 'No saved quotation found',
                    isError: !(quotations.length > 0),
                })
                setAlertOpen(true)
                setLoadingSaveQuotation(false)
                if (quotations.length > 0) {
                    setCollapseDetails(false)
                    setExpandQuotation(false)
                    setCollapseFeturesSelection(false)
                }
            })
            .catch((_) => {
                setSnackBarInfo({
                    title: 'Error retrieving quotation',
                    isError: true,
                })
                setLoadingSaveQuotation(false)
                setSavedQuotation([])
                setAlertOpen(true)
            })
    }

    const deleteCustomerQuotes = (quotationId: string) => {
        CustomerAPI.deleteCustomerQuotation(false, quotationId)
            .then((_) => {
                setSnackBarInfo({
                    title: 'Quotation deleted successfully',
                    isError: false,
                })
                setAlertOpen(true)
            })
            .catch((_) => {
                setSnackBarInfo({
                    title: 'Error deleting quotation',
                    isError: true,
                })
                setAlertOpen(true)
            })
    }
    const saveCustomerQuotations = (generatedPlan: ModelQuotation) => {
        if (selectedCustomer === undefined) return
        const payload = {
            customer_id: selectedCustomer?.id,
            product_id: selectedProduct?.id,
            requirement: JSON.stringify(requirements),
            generated_plan: JSON.stringify(generatedPlan),
        }
        CustomerAPI.saveCustomerQuotation(false, payload)
            .then((response) => {
                setSnackBarInfo({
                    title: 'Quotation saved successfully',
                    isError: false,
                })
                generatedPlan.id = response['quotation_id']
                setAlertOpen(true)
            })
            .catch((_) => {
                setSnackBarInfo({
                    title: 'Error saving quotation',
                    isError: true,
                })
                setAlertOpen(true)
            })
    }
    const handleSubmit = (isDiscounted: boolean) => {
        setSubmittingRequirements(true)

        // Creating the payload object
        const payload: { [key: string]: any } = {
            product_id: selectedProduct?.id,
            customer_requirements: selectedFeature
                .map((feature) =>
                    feature.featureType !== 'config'
                        ? {
                              feature_slug: feature.slug,
                              is_requested: feature.isRequested,
                              requested_quantity: feature.requestedQuantity,
                          }
                        : {
                              feature_slug: feature.slug,
                              is_requested: feature.isRequested,
                              requested_config_value: `${feature.selectedConfigOption}`,
                          }
                )
                .filter((feature) => feature !== null), // Filter out null entries if any
        }

        // Add discount if applicable
        if (isDiscounted) {
            payload['discounts'] = []

            if (discountFixed > 0) {
                payload['discounts'].push({
                    billing_cadence: 'ONETIME',
                    discount: {
                        type: 'percentage',
                        value: discountFixed,
                    },
                })
            }

            if (discountRecurring > 0) {
                payload['discounts'].push({
                    billing_cadence: 'RECURRING',
                    discount: {
                        type: 'percentage',
                        value: discountRecurring,
                    },
                })
            }
        }

        if (formValue.customerName !== '' && formValue.customerEmail !== '')
            payload['customer'] = {
                name: formValue.customerName,
                email: formValue.customerEmail,
            }

        payload['billing_period'] = billinPeriod.toUpperCase()
        payload['billing_cadence'] = billinPeriod.toUpperCase()

        setRequirements(payload)
        // Submit the form
        CustomerAPI.postCustomerForm(false, payload)
            .then((value) => {
                setQuotationResponse(value)
                setQuotation(value.data)
                setSelectedQuotation(value.data.find((quotation) => quotation.selected === true))
                setSubmittingRequirements(false)
                setSnackBarInfo({
                    title: 'Requirements Submitted Successfully',
                    isError: false,
                })
                setAlertOpen(true)
                setCollapseFeturesSelection(false)
                setExpandQuotation(true)
                setCollapseDetails(false)
            })
            .catch((error: AxiosError<{ [key: string]: any }>) => {
                console.log(error)
                setSnackBarInfo({
                    title: 'Failed to submit Quotation',
                    isError: true,
                })
                setAlertOpen(true)
                setSubmittingRequirements(false)
            })
    }

    useEffect(() => {
        if (!products || !selectedProduct)
            ProductAPI.getAll().then((product) => {
                setProducts(product)
                setSelectedProduct(product[0])
                onProductSelected(product[0])
            })
    }, [])

    return (
        <div className="bg-white">
            <Container>
                <Spacer height={20} />
                <div className="h-4"></div>
                <div>
                    <h1 className="font-semibold text-[24px] bg-white ">Quotation</h1>
                    <div className="w-full border mt-2"></div>
                </div>
                <Spacer height={25} />
                {/* <Accordion
                    expanded={collapseDetails}
                    sx={{
                        borderRadius: '10px',
                        justifyContent: 'start',
                        padding: '10px 10px 10px 10px',
                        backgroundColor: 'white',
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                        '&:before': {
                            display: 'none',
                        },
                    }}
                > */}
                {/* <AccordionSummary expandIcon={<ExpandMore />} onClick={() => setCollapseDetails(!collapseDetails)}>
                        <Typography fontWeight={700} fontSize={20} fontFamily={'Open Sans'}>
                            Customer details
                        </Typography>
                    </AccordionSummary> */}
                {/* <AccordionDetails> */}
                {/* <div className="mt-5 flex gap-4 items-center">
                            <FlexSelect
                                options={(products ?? []).map((product) => ({
                                    id: product.id,
                                    value: product.name,
                                }))}
                                field={{
                                    value: selectedProduct?.id,
                                    onChange: (value: any) => {
                                        const selectedProduct = products?.find((product) => product.id === value)
                                        onProductSelected(selectedProduct!)
                                    },
                                }}
                                label="Select product"
                                name="product"
                            />
                        </div> */}

                {/* <Spacer height={20} /> */}
                {/* <CustomerDropdown
                            setFormValue={setFormValue}
                            formValue={formValue}
                            setSelectedCustomer={setSelectedCustomer}
                            setAlertOpen={setAlertOpen}
                            setSnackBarInfo={setSnackBarInfo}
                            selectedValue={selectedCustomer ?? undefined}
                            onSelect={(value) => {
                                setSelectedCustomer(value)
                                getCustomerQuotes(value.id)
                            }}
                            onCreate={() => {
                                setCollapseFeturesSelection(true)
                                setSavedQuotation([])
                            }}
                        /> */}
                {/* </AccordionDetails> */}
                {/* </Accordion> */}
                {/* <Spacer height={20} /> */}
                <Accordion
                    expanded={collapseFeturesSelection}
                    sx={{
                        borderRadius: '10px',
                        justifyContent: 'start',
                        padding: '10px 10px 10px 10px',
                        backgroundColor: 'white',
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                        '&:before': {
                            display: 'none',
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        onClick={() => setCollapseFeturesSelection(!collapseFeturesSelection)}
                    >
                        <Typography fontWeight={700} fontSize={20} fontFamily={'Open Sans'}>
                            Add requirements
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <div className="flex justify-between items-start w-full gap-10">
                                <AddFeatureButton
                                    leadingIcon={<Add className="text-primary stroke-primary stroke-[1.5]" />}
                                    textLabel="Add feature"
                                    onTap={() => setOpenFeatureList(true)}
                                />

                                {/* <FlexSelect
                                    label=""
                                    name="billing_period"
                                    field={{
                                        onChange: (value: any) => {
                                            setBillingPeriod(value)
                                        },
                                        value: billinPeriod,
                                    }}
                                    options={[
                                        { value: 'Monthly', id: 'Monthly' },
                                        { value: 'Annually', id: 'Annually' },
                                    ]}
                                /> */}
                            </div>

                            <Spacer height={20} />
                            <div>
                                {loadingFeatures && <CircularProgress sx={{ color: FlexColors.primary }} />}
                                {!loadingFeatures && selectedFeature && selectedFeature.length > 0 && (
                                    <FeatureTable
                                        initialFeatures={selectedFeature}
                                        onQuantityChange={handleQuantityChange}
                                        onConfigOptionChange={handleConfigOptionChange}
                                        onDelete={handleDeleteFeature}
                                    />
                                )}
                            </div>
                            <Spacer height={20} />
                            {selectedFeature && selectedFeature.length > 0 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <FlexButton
                                        text="Submit"
                                        textSize={16}
                                        isLoading={submittingRequirements}
                                        onClick={() => handleSubmit(enableDiscount)}
                                        height={40}
                                        disable={selectedFeature.length <= 0}
                                        width={130}
                                    />
                                </div>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Snackbar
                    open={alertOpen}
                    onDurationChange={() => setAlertOpen(false)}
                    autoHideDuration={3000}
                    onClose={() => setAlertOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert
                        severity={snackBarInfo!.isError ? 'error' : 'success'}
                        sx={{ width: '100%' }}
                        onClose={() => setAlertOpen(false)}
                    >
                        {snackBarInfo.title}
                    </Alert>
                </Snackbar>
                {openFeatureList && (
                    <Dialog open={openFeatureList} onAbort={handleClose} onClose={handleClose} fullWidth maxWidth="sm">
                        <div className="px-8 pt-4">
                            <FlexTextField
                                onChange={handleFeatureSearch}
                                textLabel=""
                                inputType="text"
                                value={featureSearchText}
                                fullWidth
                                leadingIcon={
                                    <div className="px-2">
                                        <SearchIcon />
                                    </div>
                                }
                                placeHolder={'Search Feature'}
                            />
                        </div>
                        <DialogContent
                            sx={{
                                height: '400px',
                            }}
                        >
                            {featureList &&
                            featureList
                                .filter(
                                    (feature) =>
                                        !selectedFeature.some((item) => item.slug === feature.slug) &&
                                        feature.name.toLowerCase().includes(featureSearchText.toLowerCase()) // Filter based on search term
                                )
                                .sort((a, b) => a.name.localeCompare(b.name)).length > 0 ? (
                                featureList
                                    .filter(
                                        (feature) =>
                                            !selectedFeature.some((item) => item.slug === feature.slug) &&
                                            feature.name.toLowerCase().includes(featureSearchText.toLowerCase()) // Filter based on search term
                                    )
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((feature) => (
                                        <MenuItem
                                            key={feature.id}
                                            value={feature.slug}
                                            onClick={() => {
                                                handleFeatureSelect(feature.slug)
                                                setOpenFeatureList(false)
                                            }}
                                        >
                                            {feature.name}
                                        </MenuItem>
                                    ))
                            ) : (
                                <div style={{ textAlign: 'center', padding: '20px' }}>
                                    <SearchOffIcon style={{ fontSize: 80, color: 'gray', marginBottom: '20px' }} />
                                    <Typography variant="h6" gutterBottom>
                                        No Features Found
                                    </Typography>
                                </div>
                            )}
                        </DialogContent>
                    </Dialog>
                )}

                <Spacer height={20} />

                {quotations && (
                    <Accordion
                        expanded={expandQuotations}
                        onChange={(_, expanded) => setExpandQuotation(expanded)}
                        sx={{
                            borderRadius: '10px',
                            justifyContent: 'start',
                            padding: '10px 10px 15px 10px',
                            backgroundColor: 'white',
                            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                            '&:before': {
                                display: 'none',
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Typography fontSize={20} fontWeight={700}>
                                Suggested quotes
                            </Typography>
                        </AccordionSummary>
                        <Spacer height={10} />
                        <AccordionDetails>
                            <div
                                style={{
                                    justifyContent: 'space-between',
                                    alignItems: 'start',
                                    display: 'flex',
                                }}
                            >
                                <div
                                    style={{
                                        padding: '10px',
                                        border: '1px solid #E5E5E5',
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px',
                                            }}
                                        >
                                            <Loyalty htmlColor={'gray'} sx={{ height: 20, width: 20 }} />
                                            <Typography fontSize={16} fontWeight={600}>
                                                Apply discount
                                            </Typography>
                                        </div>
                                        <Spacer width={20} />
                                        <IOSSwitch
                                            checked={enableDiscount}
                                            size="small"
                                            onChange={(event) => {
                                                setEnableDiscount(event.target.checked)
                                                setOpenDiscountBox(event.target.checked)
                                                if (event.target.checked === false) {
                                                    handleSubmit(false)
                                                }
                                            }}
                                        />
                                    </div>
                                    <Spacer height={10} />
                                    {enableDiscount && (
                                        <div>
                                            {!openDiscountBox && (discountFixed > 0 || discountRecurring > 0) && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        alignContent: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight={600}
                                                        color={FlexColors.primary}
                                                    >
                                                        Discount Applied
                                                    </Typography>
                                                    <Spacer width={10} />
                                                    <IconButton
                                                        sx={{
                                                            padding: 0,
                                                        }}
                                                        onClick={() => setOpenDiscountBox(true)}
                                                    >
                                                        <BorderColor
                                                            htmlColor={FlexColors.primary}
                                                            sx={{
                                                                height: 15,
                                                                width: 15,
                                                            }}
                                                        />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className="flex flex-col items-end gap-2">
                                    <FlexButton
                                        text="Save Quotation"
                                        onClick={() => {
                                            if (selectedQuotation && !savedQuotation.includes(selectedQuotation)) {
                                                setSavedQuotation([...savedQuotation, selectedQuotation!])
                                                saveCustomerQuotations(selectedQuotation)
                                                setExpandQuotation(false)
                                                setSnackBarInfo({
                                                    title: 'Quotation saved successfully',
                                                    isError: false,
                                                })

                                                setAlertOpen(true)
                                            }
                                        }}
                                        textSize={14}
                                        disable={!selectedQuotation}
                                    />
                                    <Spacer height={5} />
                                    <LikeDislikeButton
                                        key={quotationResponse?.request_id}
                                        productId={selectedProduct!.id}
                                        requestId={quotationResponse?.request_id ?? ''}
                                    />
                                </div>
                            </div>
                            <Spacer height={20} />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {submittingRequirements && <CircularProgress sx={{ color: FlexColors.primary }} />}
                            </div>
                            {!submittingRequirements && (
                                <Quotation
                                    quotations={quotations!}
                                    onPlanSelected={(index) => {
                                        if (quotations[index].selected !== true) {
                                            const updatedQuotations = quotations!.map((quotation, i) => {
                                                if (i === index) {
                                                    return {
                                                        ...quotation,
                                                        selected: !quotation.selected,
                                                    }
                                                } else {
                                                    return {
                                                        ...quotation,
                                                        selected: false,
                                                    }
                                                }
                                            })
                                            setQuotation(updatedQuotations)
                                        }
                                        setSelectedQuotation(quotations[index])
                                    }}
                                />
                            )}
                        </AccordionDetails>
                    </Accordion>
                )}

                <Spacer height={20} />
                {loadingSaveQuotation && (
                    <CircularProgress
                        sx={{
                            color: FlexColors.primary,
                        }}
                    />
                )}

                {!loadingSaveQuotation && savedQuotation.length > 0 && (
                    <Card
                        sx={{
                            borderRadius: '10px',
                            backgroundColor: 'white',
                            paddingRight: '10px',
                            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                            justifyContent: 'center',
                        }}
                    >
                        <div
                            style={{
                                padding: '10px 10px 10px 20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography fontWeight={700} fontSize={20}>
                                Saved Quotations
                            </Typography>
                            <FlexButton
                                text="Download Quotation"
                                onClick={() =>
                                    exportMultipleQuotationToExcel({
                                        data: [...savedQuotation.map((quotation) => quotation.excel_data)],
                                        fileName: 'quotations',
                                    })
                                }
                                textSize={14}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row', // Adjust the gap as needed
                                alignItems: 'flex-start',
                                gap: '50px',
                                padding: '10px 20px 10px 20px',
                                overflowX: 'auto',
                            }}
                        >
                            {savedQuotation.length > 0 &&
                                savedQuotation.map((data, index) => {
                                    return (
                                        <div key={index}>
                                            <QuotationCard
                                                isSelected
                                                quotation={data}
                                                onPlanSelected={() => {}}
                                                onDelete={() => {
                                                    const updatedQuotations = savedQuotation.filter(
                                                        (quotation) => quotation !== data
                                                    )
                                                    setSavedQuotation(updatedQuotations)
                                                    deleteCustomerQuotes(data.id)
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                    </Card>
                )}
                <Spacer height={50} />
                <Dialog open={openDiscountBox} onClose={handleClose}>
                    <DialogContent sx={{ width: 500 }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                }}
                            >
                                <Loyalty htmlColor={FlexColors.primary} />
                                <Spacer width={10} />
                                <Typography
                                    fontSize={20}
                                    fontWeight={600}
                                    fontFamily={'Open Sans'}
                                    color={FlexColors.primary}
                                >
                                    Apply discount
                                </Typography>
                            </div>
                            <Spacer width={20} />
                            <IconButton
                                onClick={() => {
                                    if (discountFixed == 0 && discountRecurring == 0) {
                                        setEnableDiscount(false)
                                    }
                                    setOpenDiscountBox(false)
                                }}
                            >
                                <Close />
                            </IconButton>
                        </div>
                        <Spacer height={30} />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography fontWeight={600} fontSize={16}>
                                Discount type
                            </Typography>
                            <FlexDropdown
                                options={['percentage']}
                                placeHolder="percentage"
                                onSelect={(selectedOption) => {
                                    console.log('selected option', selectedOption)
                                }}
                            />
                        </div>
                        <Spacer height={20} />
                        <Typography fontWeight={600} fontSize={16}>
                            Apply on
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography color={'gray'} fontWeight={500} fontSize={14}>
                                Fixed charges
                            </Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                error={errorFixed}
                                helperText={errorFixed && 'Discount exceeds the maximum value'}
                                value={discountFixed}
                                onChange={(event) => {
                                    let value = event.target.value
                                    if (value === '') {
                                        value = '0'
                                    }

                                    const guardRail: ModelGuardRail | undefined = productGuardRail?.find(
                                        (item) => item.effect_on === 'cadence_fixed_price'
                                    )

                                    if (guardRail) {
                                        if (
                                            !validateEffectValue(
                                                Number.parseInt(value),
                                                guardRail.operator,
                                                guardRail.effect_value
                                            )
                                        ) {
                                            setErrorFixed(true)
                                        } else {
                                            setErrorFixed(false)
                                        }
                                    }
                                    setDiscountFixed(Number.parseInt(value))
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </div>
                        <Spacer height={20} />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography color={'gray'} fontWeight={500} fontSize={14}>
                                Recurring charges
                            </Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                value={discountRecurring}
                                error={errorRecurring}
                                helperText={errorRecurring && 'Discount exceeds the maximum value'}
                                onChange={(event) => {
                                    let value = event.target.value
                                    if (value === '') {
                                        value = '0'
                                    }

                                    const guardRail: ModelGuardRail | undefined = productGuardRail?.find(
                                        (item) => item.effect_on === 'cadence_recurring_price'
                                    )

                                    if (guardRail) {
                                        if (
                                            !validateEffectValue(
                                                Number.parseInt(value),
                                                guardRail.operator,
                                                guardRail.effect_value
                                            )
                                        ) {
                                            setErrorRecurring(true)
                                        } else {
                                            setErrorRecurring(false)
                                        }
                                    }
                                    setDiscountRecurring(Number.parseInt(value))
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end',

                                marginTop: '20px',
                            }}
                        >
                            <FlexButton
                                textSize={16}
                                width={100}
                                height={35}
                                radius={10}
                                text="Apply"
                                onClick={() => {
                                    if (!errorFixed && !errorRecurring) {
                                        setOpenDiscountBox(false)
                                        handleSubmit(true)
                                    }
                                }}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </Container>
        </div>
    )
}

import { Alert, Box, CircularProgress, Container, Typography } from '@mui/material'
import Spacer from '../../components/common/util/spacer'
import PriceFormPopup from '../../components/price_form/price_form_popup'
import { useEffect, useState } from 'react'
import { ProductDetailAPI } from '@/api/req/product_detail_api.ts'
import FLexTable from '../../components/common/table/table'
import SwitchSelector from '../../components/common/switch_selector/switch_selector'
import { ModelProduct } from '@/api/models/model_product.ts'
import { ProductAPI } from '@/api/req/product_api.ts'
import { Plan } from '@/api/models/model_product_detail.ts'
import FlexColors from '@/utils/colors.ts'
import PlanCard from '@/components/plan_card/plan_card'
import { FlexSelect } from '@/@ui-lib/atoms'

export default function PricePage() {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [billing, setBilling] = useState('Monthly')
    const [plans, setPlans] = useState<Plan[]>([])
    const [addOns, setAddOns] = useState<Plan[]>([])
    const [products, setProducts] = useState<ModelProduct[]>()
    const [selectedProduct, setSelectedProduct] = useState<ModelProduct>()
    const [showMore, setShowMore] = useState(false)

    useEffect(() => {
        ProductAPI.getAll().then((result: ModelProduct[]) => {
            setProducts(result)
            setSelectedProduct(result[0])
            setLoading(true)
            ProductDetailAPI.getAll(result[0].id)
                .then((product) => {
                    setPlans(product.plans)
                    setAddOns(product.add_ons)
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }, [])

    const onHandleChange = (id: string) => {
        setLoading(true)
        ProductDetailAPI.getAll(id)
            .then((product) => {
                setPlans(product.plans)
                setAddOns(product.add_ons)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div className="bg-white">
            <Container>
                {/* <div
                className={'shadow-lg bg-white'}
                style={{
                    alignContent: 'start',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                }}
            >
                    <Typography fontWeight={700} fontSize={32}>
                        Pricing Page
                    </Typography>
                <Spacer height={20} />

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div className="mt-5 flex gap-4 items-center">
                        <FlexSelect
                            options={(products ?? []).map((product) => ({
                                id: product.id,
                                value: product.name,
                            }))}
                            field={{
                                value: selectedProduct?.id,
                                onChange: (value: any) => {
                                    const selectedProduct = products?.find((product) => product.id === value)
                                    setSelectedProduct(selectedProduct!)
                                    onHandleChange(selectedProduct!.id)
                                },
                            }}
                            label="Select product"
                            name="product"
                        />
                    </div>
                    <SwitchSelector
                        options={['Billed Monthly', 'Billed Annually']}
                        width={280}
                        handleSwitchClick={(value) => {
                            if (value === 'Billed Monthly') {
                                setBilling('Monthly')
                            } else {
                                setBilling('Annually')
                            }
                        }}
                        activeOption={billing === 'Monthly' ? 'Billed Monthly' : 'Billed Annually'}
                    />
                </div>
            </div> */}

                {/* TODO: Remove this after demo  */}
                {/* demo header  */}
                <div className="h-10"></div>
                <div>
                    <h1 className="font-semibold text-[24px] bg-white ">Pricing Page</h1>
                    <div className="w-full border mt-2"></div>
                </div>
                <div className="w-full flex mt-12 justify-center items-center">
                    <SwitchSelector
                        options={['Billed Monthly', 'Billed Annually']}
                        width={280}
                        handleSwitchClick={(value) => {
                            if (value === 'Billed Monthly') {
                                setBilling('Monthly')
                            } else {
                                setBilling('Annually')
                            }
                        }}
                        activeOption={billing === 'Monthly' ? 'Billed Monthly' : 'Billed Annually'}
                    />
                </div>

                <Spacer height={20} />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {loading && (
                        <div
                            className={
                                'min-h-[200px] w-full flex justify-center items-center bg-white rounded-md shadow-md'
                            }
                        >
                            <CircularProgress size={50} style={{ color: FlexColors.primary }} />
                        </div>
                    )}
                </div>
                <div className="grid grid-cols-3 gap-4">
                    {!loading && (!plans || plans.length === 0) && (
                        <Box sx={{ width: '100%' }}>
                            <Alert severity="warning">No plans available</Alert>
                        </Box>
                    )}
                    {!loading &&
                        plans &&
                        plans.length > 0 &&
                        plans.map(
                            (plan) =>
                                (plan.feature_mappings ?? []).length > 0 && (
                                    <div
                                        key={plan.id}
                                        className="items-start p-4 rounded-lg shadow-[1px_2px_21px_rgba(0,0,0,0.1)] border h-full flex flex-col justify-between w-full"
                                    >
                                        <PlanCard
                                            showMore={showMore}
                                            plan={plan}
                                            setShowMore={setShowMore}
                                            billing={billing}
                                        />
                                    </div>
                                )
                        )}
                </div>
                <PriceFormPopup open={open} onClose={handleClose} />
                <Spacer height={10} />
                {/* {
                    !loading && (
                        <div>
                            <Typography fontWeight={700} fontSize={24}>
                                Add-ons
                            </Typography>
                            <Spacer height={10} />

                            <FLexTable columnlabel={['Name', 'Slug', 'Status', 'Last Updated']} data={addOns} />
                        </div>
                    )
                } */}
            </Container>
        </div>
    )
}

import React, { useEffect, useRef, useState } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import { LuMoveVertical } from 'react-icons/lu'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import Chip from '@/components/common/chip/chip'
import { Alert, CircularProgress, Container, Snackbar } from '@mui/material'
import FlexColors from '@/utils/colors.ts'
import { ModelCustomer } from '@/api/models/model_customer'
import { CustomerAPI } from '@/api/req/customer_api'
import { ColDef } from 'ag-grid-community'
import '../addon/index.css'
import { ModelSnackbar } from '../sales/QuotationPage'
import CustomerDrawer from './CustomerDrawer'
import { useNavigate } from 'react-router-dom'
import { Button } from '@/@ui-lib/atoms'
import { Plus } from 'lucide-react'

export interface PriceTierV2 {
    from: number | string
    to: number | string
    price: number
}

export interface ModelTableData {
    name: string
    slug: string
    tier_type: string
    status: string
    price: PriceTierV2[] | number
    updated_at: Date
}

function formatDate(timestamp: string) {
    const date = new Date(timestamp)
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })
}

const ActionCellRenderer: React.FC<{
    onDelete: () => void
    onEdit: () => void
    onCreateSubscription: () => void
}> = ({ onDelete, onEdit, onCreateSubscription }) => {
    return (
        <div className="size-6">
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <LuMoveVertical className={'text-xl '} />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem onClick={onEdit}>Edit</DropdownMenuItem>
                    <DropdownMenuItem onClick={onDelete}>Delete</DropdownMenuItem>
                    <DropdownMenuItem onClick={onCreateSubscription}>Create Subscription</DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}

const Customer = () => {
    const gridRef = useRef<AgGridReact>(null)

    const [loading, setLoading] = useState(true)
    const [alertOpen, setAlertOpen] = useState(false)
    const [customers, setCustomers] = useState<ModelCustomer[]>([])
    const [snackBarInfo, setSnackBarInfo] = useState<ModelSnackbar>({
        title: '',
        isError: false,
    })

    const navigate = useNavigate()
    useEffect(() => {
        getAllCustomers()
    }, [])

    const getAllCustomers = () => {
        setLoading(true)
        CustomerAPI.getAll().then((result: ModelCustomer[]) => {
            setCustomers(result)
            setLoading(false)
        })
    }

    const deleteCustomer = (props: any) => {
        setLoading(true)
        CustomerAPI.delete(false, props.data.id)
            .then((response) => {
                if (response) {
                    setSnackBarInfo({
                        title: 'Customer deleted successfully',
                        isError: false,
                    })
                    setLoading(false)
                    setAlertOpen(true)
                    setCustomers((prev) => {
                        return prev.filter((item) => item.id !== props.data.id)
                    })
                } else {
                    setSnackBarInfo({
                        title: 'Customer deletion failed',
                        isError: true,
                    })
                    setLoading(false)
                    setAlertOpen(true)
                }
            })
            .catch((_) => {
                setSnackBarInfo({
                    title: 'Customer deletion failed',
                    isError: true,
                })
                setLoading(false)
                setAlertOpen(true)
            })
    }

    const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false)
    const [selectedCustomer, setSelectedCustomer] = useState<ModelCustomer>()
    const colDefs: ColDef[] = [
        {
            headerName: 'Name',
            field: 'name',
            sortable: true,
            flex: 1,
            cellStyle: { cursor: 'pointer' },
        },
        {
            headerName: 'Status',
            field: 'status',
            cellRenderer: (params: any) => (
                <div className="flex w-full items-center h-full cursor-pointer">
                    <Chip isActive={params.value === 'published'} />
                </div>
            ),
            sortable: true,
            flex: 1,
        },
        {
            headerName: 'Slug',
            field: 'slug',
            sortable: true,
            flex: 1,
            cellStyle: { cursor: 'pointer' },
        },
        {
            headerName: 'Last updated',
            field: 'updated_at',
            valueGetter: (s: any) => formatDate(s.data.updated_at),
            sortable: true,
            flex: 1,
            cellStyle: { cursor: 'pointer' },
        },
        {
            headerName: '',
            field: 'actions',
            cellRenderer: (props: any) => (
                <div className="cursor-pointer">
                    <ActionCellRenderer
                        onDelete={() => deleteCustomer(props)}
                        onEdit={() => {
                            setSelectedCustomer(props.data)
                            setDrawerVisibility(true)
                        }}
                        onCreateSubscription={() => {
                            navigate(`subscription?id=${props.data?.id}`)
                        }}
                    />
                </div>
            ),
            pinned: 'right',
            width: 50,
        },
    ]

    const onGridReady = () => {
        gridRef.current!.api.sizeColumnsToFit()
    }

    return (
        <div className=" min-h-screen w-full bg-white">
            <Container>
                {/* <div className="p-4 bg-white card flex justify-between items-center mt-8">
                    <h1 className="text-3xl font-bold">Customers</h1>
                    <FlexButton
                        text="Add Customer"
                        textSize={16}
                        height={40}
                        isLoading={false}
                        onClick={() => {
                            setSelectedCustomer(undefined) // Ensure drawer opens in "Add" mode
                            setDrawerVisibility(true)
                        }}
                    />
                </div> */}

                {/* TODO: Remove this after demo  */}
                {/* demo header  */}
                <div className="h-8"></div>
                <div className="w-full flex justify-between items-center">
                    <h1 className="font-semibold text-[24px] bg-white ">Customers</h1>

                    <Button
                        label="Add Customer"
                        prefixIcon={<Plus />}
                        onClick={() => {
                            setSelectedCustomer(undefined) // Ensure drawer opens in "Add" mode
                            setDrawerVisibility(true)
                        }}
                    />
                </div>
                <div className="w-full border mt-2"></div>

                <div className="ag-theme-quartz mt-12 w-full bg-white">
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={colDefs}
                        rowData={customers}
                        defaultColDef={{
                            cellStyle: {
                                fontSize: '16px',
                                fontWeight: '400',
                            },
                        }}
                        gridOptions={{
                            headerHeight: 35,
                            domLayout: 'autoHeight', // Automatically adjust height to fit rows
                        }}
                        onCellClicked={(props) => navigate(`detail?id=${props.data?.id}`)}
                        loading={loading}
                        loadingOverlayComponent={() => (
                            <CircularProgress size={50} style={{ color: FlexColors.primary }} />
                        )}
                        onGridReady={onGridReady}
                    />
                </div>

                <Snackbar
                    open={alertOpen}
                    onDurationChange={() => setAlertOpen(false)}
                    autoHideDuration={3000}
                    onClose={() => setAlertOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert
                        severity={snackBarInfo!.isError ? 'error' : 'success'}
                        sx={{ width: '100%' }}
                        onClose={() => setAlertOpen(false)}
                    >
                        {snackBarInfo!.title}
                    </Alert>
                </Snackbar>
                <CustomerDrawer
                    key={selectedCustomer?.slug}
                    drawerVisibility={drawerVisibility}
                    customer={selectedCustomer}
                    onSuccess={() => {
                        setDrawerVisibility(false)
                        getAllCustomers()
                    }}
                    onClose={() => {
                        setDrawerVisibility(false)
                    }}
                    onError={(value) => {
                        setSnackBarInfo({
                            title: value.message,
                            isError: true,
                        })
                        setAlertOpen(true)
                    }}
                />
            </Container>
        </div>
    )
}

export default Customer

import React from 'react'
import { FeatureMapping, Price } from '../api/models/model_product_detail'
import { formatNumberUS } from './utility_functions'

export function getPlanPriceDetails(prices: Price[], showMonthly: boolean): React.ReactNode {
    return (
        <div>
            {prices &&
                prices
                    .filter((prices) => prices.billing_period === (showMonthly ? 'MONTHLY' : 'ANNUALLY'))
                    .map((price, index) => (
                        <div key={index} className=" rounded-md p-1 shadow-sm bg-white">
                            {price.billing_cadence === 'ONETIME' ? (
                                <div className="text-[16px] flex items-center italic">
                                    <span className=" font-medium">{'$ ' + price.monthly_price}</span>
                                    <div className="w-2" />
                                    <span className="text-sm  text-[#8A8A8A]">One Time</span>
                                </div>
                            ) : (
                                <div className="text-[16px] flex items-center italic">
                                    <span className="font-medium">
                                        {'$ ' + (showMonthly ? price.monthly_price : price.annual_price)}
                                    </span>
                                    <div className="w-2" />
                                    <span className="text-sm font-medium text-[#8A8A8A]">
                                        {/* Billed {showMonthly ? 'Monthly' : 'Annually'} */}
                                        Billed {'Monthly'}
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
        </div>
    )
}

export function showFeatureDetail(feature: FeatureMapping): React.ReactNode {
    let value = ''

    if (feature.feature.type === 'meter') {
        const usageLimit = feature.configuration.usage_limit
        const hasUnlimitedUsage = feature.configuration.has_unlimited_usage
        const unit = usageLimit > 0 ? feature.feature.unit_plural : feature.feature.unit

        if (usageLimit === 0) {
            value = ''
        } else {
            value = formatNumberUS(usageLimit)
        }

        if (hasUnlimitedUsage) {
            value += ' Unlimited'
        } else {
            value += ` ${unit}`
        }

        // TODO: Look into this in which way we need to add suffix i.e. monthly ,annually etc
        // value += ` ${feature.feature.name} ${feature.configuration.reset_period.toLowerCase()}`
        value += ` ${feature.feature.name}`
    } else if (feature.feature.type === 'config') {
        value = feature.configuration.config_value + ' ' + feature.feature.name
    } else {
        value = feature.feature.name
    }

    return <div>{value}</div>
}

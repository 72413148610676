import Spacer from '../util/spacer'

interface SwitchSelectorProps {
    options: string[]
    width?: number | undefined
    activeOption: string
    handleSwitchClick: (option: string) => void
}

export default function SwitchSelector({ options, handleSwitchClick, width, activeOption }: SwitchSelectorProps) {
    return (
        <div
            className="flex justify-evenly bg-white rounded-md border border-gray-200 py-1 px-1 select-none"
            style={{ width: width ?? '280px' }}
        >
            <div
                className={`font-inter px-3 py-2 rounded-md font-bold text-sm cursor-pointer 
                ${activeOption === options[0] ? 'text-blue-700 bg-blue-700 bg-opacity-5' : 'text-gray-500'}`}
                onClick={() => handleSwitchClick(options[0])}
            >
                {options[0]}
            </div>
            <Spacer width={5} />
            <div
                className={`font-inter px-3 py-2 rounded-md font-bold text-sm cursor-pointer 
                ${activeOption === options[1] ? 'text-blue-700 bg-blue-700 bg-opacity-5' : 'text-gray-500'}`}
                onClick={() => handleSwitchClick(options[1])}
            >
                {options[1]}
            </div>
        </div>
    )
}

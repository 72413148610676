import { GrDocument } from 'react-icons/gr'
import { formatNumberUS, getCurrencySymbol, SuperAddOnConversion, SuperPlanConversion } from '@/utils/utility_functions'
import Spacer from '@/components/common/util/spacer'
import { PriceTier } from '@/api/models/model_product_detail'

interface SubscriptionPreviewProps {
    productName?: string
    planName?: string
    fixedDiscount: number
    recurringDiscount: number
    plans: SuperPlanConversion
    addons: SuperAddOnConversion
    selectedPlanId: string
    selectedAddonIds: string[]
    billingPeriod: string
    startDate: Date
}

const getDaySuffix = (day: number): string => {
    if (day < 1 || day > 31) {
        throw new Error('Invalid day. It should be between 1 and 31.')
    }

    if (day % 10 === 1 && day !== 11) {
        return day + 'st'
    } else if (day % 10 === 2 && day !== 12) {
        return day + 'nd'
    } else if (day % 10 === 3 && day !== 13) {
        return day + 'rd'
    } else {
        return day + 'th'
    }
}
const calculateDiscount = (discount: number, price: number) => {
    return (discount / 100) * price
}

const calculateTieredPrice = (quantity: number, tiered: PriceTier[] | undefined) => {
    if (!tiered) return 0
    for (const tier of tiered) {
        const upTo = tier.up_to === 999999999 ? Infinity : Number(tier.up_to)
        if (quantity <= upTo) {
            return tier.price
        }
    }

    return 0
}

export default function SubscriptionPreview({
    plans,
    addons,
    selectedPlanId,
    selectedAddonIds,
    billingPeriod,
    productName,
    planName,
    fixedDiscount,
    recurringDiscount,
    startDate,
}: SubscriptionPreviewProps) {
    const selectedPlan = plans[selectedPlanId]
    console.log('Addon IDs: ', selectedAddonIds)
    const selectedAddons = selectedAddonIds.map((id) => addons[id])
    console.log('Addons: ', addons)
    console.log('Selected Addons: ', selectedAddons)

    const baseChargePrice =
        billingPeriod === 'monthly' ? selectedPlan.base_charge?.monthly?.price : selectedPlan.base_charge?.annual?.price
    const oneTimeChargePrice = selectedPlan.onetime?.price

    // Calculate usage based charges total
    const usageBasedTotal = Object.keys(selectedPlan.usage_based_charge ?? {}).reduce((total, key) => {
        const usageBasedCharge =
            billingPeriod === 'MONTHLY'
                ? selectedPlan.usage_based_charge?.[key].monthly
                : selectedPlan.usage_based_charge?.[key].annual
        const quantity = selectedPlan.usage_based_charge?.[key].quantity ?? 0
        const price =
            usageBasedCharge?.type === 'flat'
                ? usageBasedCharge?.price
                : calculateTieredPrice(quantity, usageBasedCharge?.price_tiers)
        return total + quantity * (price ?? 0)
    }, 0)

    console.log(selectedAddons)
    // Calculate addons total
    const addonsTotal = selectedAddons.reduce((total, addon) => {
        if (addon === undefined) return total
        const addOnCharge = billingPeriod === 'monthly' ? addon.monthly : addon.annual
        const quantity = addon.quantity ?? 0
        const price =
            addOnCharge?.type === 'FLAT' ? addOnCharge?.price : calculateTieredPrice(quantity, addOnCharge?.price_tiers)
        return total + quantity * (price ?? 0)
    }, 0)

    // Calculate subtotal before discounts
    const subtotal = (baseChargePrice ?? 0) + (oneTimeChargePrice ?? 0) + usageBasedTotal + addonsTotal

    // Calculate discount amounts
    const fixedDiscountAmount = calculateDiscount(fixedDiscount, oneTimeChargePrice ?? 0)
    const recurringDiscountAmount = calculateDiscount(recurringDiscount, subtotal - (oneTimeChargePrice ?? 0))

    // Calculate final total after discounts
    const totalPrice = subtotal - fixedDiscountAmount - recurringDiscountAmount

    return (
        <div>
            <div className="px-5 py-5 border rounded-lg border-gray-600 fill-background bg-primary bg-opacity-[20%]">
                <div className="flex text-xl gap-5 w-full content-center">
                    <GrDocument className="h-6 w-6" />
                    <span className="text-xl font-bold">Subscription Preview</span>
                </div>
                <Spacer height={10} />
                <span className="text-sm font-semibold">Recurring Charges</span>
                <div className="flex gap-1 mt-3 text-sm">
                    <span>{productName}/</span>
                    <span>{planName}</span>
                </div>
                {Object.keys(selectedPlan).map((key) => {
                    if (key === 'base_charge') {
                        const billingDetails =
                            billingPeriod === 'monthly'
                                ? selectedPlan.base_charge?.monthly
                                : selectedPlan.base_charge?.annual
                        return (
                            <div key={key} className="mt-2 flex text-sm justify-between">
                                <div className="text-gray-800">Base</div>
                                <div className="text-gray-700">
                                    <div>
                                        {getCurrencySymbol(billingDetails?.currency ?? 'USD')}
                                        {formatNumberUS(baseChargePrice ?? 0)}
                                    </div>
                                </div>
                            </div>
                        )
                    } else if (key === 'onetime') {
                        return (
                            <div key={key} className="mt-2 flex text-sm justify-between">
                                <div className="text-gray-800">One-time</div>
                                <div className="text-gray-700">
                                    <div>
                                        {getCurrencySymbol(selectedPlan.onetime?.currency ?? 'USD')}
                                        {formatNumberUS(oneTimeChargePrice ?? 0)}
                                    </div>
                                </div>
                            </div>
                        )
                    } else if (key === 'usage_based_charge') {
                        Object.keys(selectedPlan.usage_based_charge ?? {}).map((key) => {
                            const usageBasedCharge =
                                billingPeriod === 'MONTHLY'
                                    ? selectedPlan.usage_based_charge?.[key].monthly
                                    : selectedPlan.usage_based_charge?.[key].annual
                            const quantity = selectedPlan.usage_based_charge?.[key].quantity ?? 0
                            const price =
                                usageBasedCharge?.type === 'flat'
                                    ? usageBasedCharge?.price
                                    : calculateTieredPrice(quantity, usageBasedCharge?.price_tiers)
                            return (
                                <div key={key} className="mt-2 flex text-sm justify-between">
                                    <div className="text-gray-800">{selectedPlan.usage_based_charge?.[key].slug}</div>
                                    <div className="text-gray-700">
                                        <div className="flex items-center">
                                            <span>
                                                {quantity} x {getCurrencySymbol(usageBasedCharge?.currency ?? 'USD')}
                                                {formatNumberUS(price!)}
                                            </span>
                                            <span className="ml-2">
                                                = {getCurrencySymbol(usageBasedCharge?.currency ?? 'USD')}
                                                {formatNumberUS(quantity * price!)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    return null
                })}
                <Spacer height={10} />
                {selectedAddonIds.length > 0 && (
                    <div className="flex items-center">
                        <span className="font-semibold">Add-ons</span>
                        <div className="flex-grow ml-2 border-t border-gray-900"></div>
                    </div>
                )}
                {selectedAddons &&
                    selectedAddons.map((addon) => {
                        if (addon === undefined) return null
                        const addOnCharge = billingPeriod === 'monthly' ? addon.monthly : addon.annual

                        const quantity = addon.quantity ?? 0

                        const price =
                            addOnCharge?.type === 'FLAT'
                                ? addOnCharge?.price
                                : calculateTieredPrice(quantity, addOnCharge?.price_tiers)

                        return (
                            <div key={addon.id} className="mt-2 flex text-sm justify-between">
                                <div className="text-gray-800">{addon.name}</div>
                                <div className="text-gray-700">
                                    <div className="flex items-center">
                                        <span>
                                            {quantity} x {getCurrencySymbol(addOnCharge?.currency ?? 'USD')}
                                            {formatNumberUS(price)}
                                        </span>
                                        <span className="ml-2">
                                            = {getCurrencySymbol(addOnCharge?.currency ?? 'USD')}
                                            {formatNumberUS(quantity * price)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                {(fixedDiscount > 0 || recurringDiscount > 0) && (
                    <>
                        <div className="mt-3 flex-grow border-t border-gray-300"></div>
                        <div className="mt-2 text-sm font-semibold">Discounts</div>
                    </>
                )}
                {fixedDiscount > 0 && (
                    <div className="mt-2 text-sm flex justify-between text-gray-800">
                        <span>One-time Discount ({fixedDiscount}%)</span>
                        <span className="text-red-600">
                            -{getCurrencySymbol(selectedPlan?.onetime?.currency ?? 'USD')}
                            {formatNumberUS(fixedDiscountAmount)}
                        </span>
                    </div>
                )}
                {recurringDiscount > 0 && (
                    <div className="mt-2 text-sm flex justify-between text-gray-800">
                        <span>Recurring Discount ({recurringDiscount}%)</span>
                        <span className="text-red-600">
                            -{getCurrencySymbol(selectedPlan?.onetime?.currency ?? 'USD')}
                            {formatNumberUS(recurringDiscountAmount)}
                        </span>
                    </div>
                )}

                <div>
                    <div className="mt-1 flex-grow border-t border-gray-900"></div>
                    <div className="mt-1 flex justify-between text-l font-bold">
                        <span>Total</span>
                        <span>
                            {getCurrencySymbol(selectedPlan?.onetime?.currency ?? 'USD')}
                            {formatNumberUS(totalPrice)}
                        </span>
                    </div>
                </div>
            </div>

            <Spacer height={10} />

            <div className="px-10 py-5 border rounded-lg border-primary fill-background bg-primary bg-opacity-5">
                <span>
                    The customer will be billed{' '}
                    <span className="font-bold text-primary">
                        {getCurrencySymbol(selectedPlan?.onetime?.currency ?? 'USD')}
                        {formatNumberUS(totalPrice + fixedDiscountAmount - (oneTimeChargePrice ?? 0))}
                    </span>{' '}
                    for this subscription every month on the{' '}
                    <span className={'text-primary font-semibold'}>{getDaySuffix(startDate.getDate())}</span>
                </span>
            </div>
        </div>
    )
}

import { useState } from 'react'

interface FloatingChatButtonProps {
    icon: React.ReactNode
    component: React.ReactNode
}
const FloatingChat = ({ component, icon }: FloatingChatButtonProps) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="fixed bottom-5 right-5">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center justify-center w-12 h-12 bg-primary text-white rounded-full shadow-lg hover:bg-primary focus:outline-none"
            >
                {icon}
            </button>

            {isOpen && component}
        </div>
    )
}

export default FloatingChat

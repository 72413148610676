import { KeyboardArrowDown } from '@mui/icons-material'
import { Typography } from '@mui/material'
import React from 'react'
import Spacer from '../../components/common/util/spacer'

interface AddFeatureProps {
    leadingIcon: React.ReactNode
    textLabel: string
    onTap?: () => void
}

export default function AddFeatureButton({ leadingIcon, textLabel, onTap }: AddFeatureProps) {
    return (
        <div
            className="flex items-center justify-between p-2 w-[40%] h-10 border border-primary bg-primary rounded-md cursor-pointer bg-opacity-10"
            onClick={onTap}
        >
            <div className="flex items-center">
                {leadingIcon}
                <Spacer width={10} />
                <Typography fontSize={16} fontWeight={'bold'}>
                    {textLabel}
                </Typography>
            </div>
            <KeyboardArrowDown />
        </div>
    )
}

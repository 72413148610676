import { CircularProgress } from '@mui/material'
import React from 'react'

interface FlexButtonProps {
    label: string
    type?: 'filled' | 'outlined'
    onClick?: () => void
    isLoading?: boolean
    disabled?: boolean
}

// FlexButton component
const FlexButton: React.FC<FlexButtonProps> = ({
    label,
    type = 'filled',
    onClick,
    isLoading = false,
    disabled = false,
}) => {
    const buttonStyles =
        type === 'filled'
            ? 'bg-primary text-white border-primary'
            : 'bg-transparent hover:bg-orange-100 text-primary border-primary'

    return (
        <button
            className={`px-6 py-2 rounded-md border font-semibold transition-all duration-200
                  ${buttonStyles} 
                    ${
                        disabled || isLoading
                            ? 'opacity-50 cursor-not-allowed'
                            : 'hover:bg-primary hover:border-pribg-primary'
                    }
                  flex items-center justify-center`}
            onClick={onClick}
            disabled={disabled || isLoading}
        >
            {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : label}
        </button>
    )
}

export default FlexButton

import { useEffect, useMemo, useState } from 'react'
import { ModelProduct } from '@/api/models/model_product.ts'
import { ProductAPI } from '@/api/req/product_api'
import { Alert, CircularProgress, Container, Snackbar } from '@mui/material'
import { CSVBoxButton } from '@csvbox/react'
import { exportToExcel, getLicenseKey } from '@/utils/utility_functions'
import { ExportAPI } from '@/api/req/export_api'
import { ImportAPI } from '@/api/req/import_api'
import Spacer from '@/components/common/util/spacer'
import { FlexSelect } from '@/@ui-lib/atoms'

interface ModelTab {
    id: number
    title: string
    key: string
    icon: string
    selected_icon: string
    sample_link: string
}
const ImportExport = () => {
    const tablist: ModelTab[] = [
        {
            id: 0,
            title: 'Plans',
            key: 'plan',
            icon: '/assets/svg/plan.svg',
            selected_icon: '/assets/svg/selected-plan.svg',
            sample_link:
                'https://docs.google.com/spreadsheets/d/1H-HnjgfDXN5EcOETrf9OOhHadMfnbLrj/edit?usp=drive_link&ouid=102019166878897295842&rtpof=true&sd=true',
        },
        // {
        //   id: 1,
        //   title: "Price",
        //   key: "price",
        //   icon: "/assets/svg/price-tag.svg",
        //   selected_icon: "/assets/svg/selected-price-tag.svg",
        // },
        {
            id: 1,
            title: 'Features',
            key: 'feature',
            icon: '/assets/svg/features.svg',
            selected_icon: '/assets/svg/selected-features.svg',
            sample_link:
                'https://docs.google.com/spreadsheets/d/1f6ZFr2X0TSbcZ_Q1K3ZScQ4yuHP453GM/edit?usp=drive_link&ouid=102019166878897295842&rtpof=true&sd=true',
        },
        {
            id: 2,
            title: 'Feature Mapping',
            key: 'feature_mapping',
            icon: '/assets/svg/marketing-plan.svg',
            selected_icon: '/assets/svg/selected-marketing-plan.svg',
            sample_link:
                'https://docs.google.com/spreadsheets/d/1IPzM1ky2uOI3DwkVhtnkvS_SfirO88z_/edit?usp=drive_link&ouid=102019166878897295842&rtpof=true&sd=true',
        },
    ]

    const [products, setProducts] = useState<ModelProduct[]>()
    const [selectedProduct, setSelectedProduct] = useState<ModelProduct | undefined>(undefined)

    const [selectedTab, setSelectedTab] = useState<ModelTab>(tablist[0])
    const [openAlert, setOpenAlert] = useState(false)
    const [submittingExcel, setSubmittingExcel] = useState(false)

    const [snackBarInfo, setSnackBarInfo] = useState<{
        title: string
        isError: boolean
    } | null>(null)

    const csvBoxKey = useMemo(
        () => `${getLicenseKey(selectedTab.key)}-${JSON.stringify(selectedTab?.title)}`,
        [selectedTab]
    )

    const handleExport = async () => {
        setSubmittingExcel(true)
        const payload = {
            product_id: selectedProduct?.id ?? '',
            type: selectedTab.key,
        }
        try {
            const value = await ExportAPI.export(false, payload)
            exportToExcel({
                json: value,
                fileName: `${selectedProduct!.name}-${selectedTab.key}`,
            })
            setSnackBarInfo({
                title: 'Data exported successfully',
                isError: false,
            })
        } catch (error) {
            setSnackBarInfo({
                title: 'Data export failed due to ' + error,
                isError: true,
            })
        } finally {
            setOpenAlert(true)
            setSubmittingExcel(false)
        }
    }

    useEffect(() => {
        const fetchProducts = async () => {
            ProductAPI.getAll().then((value) => {
                setProducts(value)
                setSelectedProduct(value[0])
            })
        }

        fetchProducts()
    }, [])

    return (
        <div className="bg-white">
            <Container>
                <div className=" min-h-screen w-full">
                    {submittingExcel && (
                        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-50 bg-opacity-50">
                            <CircularProgress />
                        </div>
                    )}
                    {/* <div className="p-4 bg-white card mt-8">
                    <h1 className="text-3xl font-bold">Import - Export</h1>

                    <div className="mt-5 flex gap-4 items-center">
                        <FlexSelect
                            options={(products ?? []).map((product) => ({
                                id: product.id,
                                value: product.name,
                            }))}
                            field={{
                                value: selectedProduct?.id,
                                onChange: (value: any) => {
                                    const selectedProduct = products?.find((product) => product.id === value)
                                    setSelectedProduct(selectedProduct!)
                                },
                            }}
                            label="Select product"
                            name="product"
                        />
                    </div>
                </div> */}
                    <div className="h-10"></div>
                    <div>
                        <h1 className="font-semibold text-[24px] bg-white ">Import-Export</h1>
                        <div className="w-full border mt-2"></div>
                    </div>
                    <div className="w-full flex mt-12 justify-center items-center"></div>

                    <div className="p-4 bg-white card my-4">
                        <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6">
                            {tablist.map((tab) => (
                                <div
                                    key={tab.id}
                                    onClick={() => setSelectedTab(tab)}
                                    className={`p-3 flex select-none active:scale-95 transition-all duration-300 justify-center items-center gap-3 rounded-md shadow-md border-2 ${
                                        selectedTab.id === tab.id
                                            ? 'text-primary border-primatext-primary'
                                            : 'text-[#616161]'
                                    }`}
                                >
                                    <img
                                        src={selectedTab.id === tab.id ? tab.selected_icon : tab.icon}
                                        alt={tab.title}
                                    />
                                    <p className="text-2xl font-bold">{tab.title}</p>
                                </div>
                            ))}
                        </div>

                        <div className="items-center gap-2 justify-center my-5">
                            <p className="text-center text-[#373737]">
                                Select a module to start importing or exporting data for
                            </p>
                            <div className="text-center">
                                <a
                                    href={selectedTab.sample_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-primary hover:underline"
                                >
                                    {'Click here '}
                                </a>
                                view sample for {selectedTab.title}
                            </div>
                        </div>

                        <div className="flex w-full items-center justify-center gap-8">
                            <CSVBoxButton
                                key={csvBoxKey}
                                licenseKey={getLicenseKey(selectedTab.key)}
                                user={{ user_id: 'user_id' }}
                                onImport={async (result, data) => {
                                    if (result) {
                                        setSubmittingExcel(true)
                                        try {
                                            const value = await ImportAPI.import(false, {
                                                product_id: selectedProduct?.id ?? '',
                                                type: selectedTab.key,
                                                url: data['raw_file'],
                                            })
                                            setSnackBarInfo({
                                                title: value ? 'Data imported successfully' : 'Data import failed',
                                                isError: !value,
                                            })
                                        } catch (error) {
                                            setSnackBarInfo({
                                                title: 'Data import failed due to' + error,
                                                isError: true,
                                            })
                                        } finally {
                                            setOpenAlert(true)
                                            setSubmittingExcel(false)
                                        }
                                    } else {
                                        setSnackBarInfo({
                                            title: 'Data import failed',
                                            isError: true,
                                        })
                                        setOpenAlert(true)
                                    }
                                }}
                                render={(launch, isLoading) => (
                                    <button
                                        disabled={isLoading}
                                        onClick={launch}
                                        style={{ opacity: isLoading ? 0.4 : 1 }}
                                        className="bg-primary text-white font-semibold py-2 px-8 rounded-md shadow-md active:scale-95 transition-all duration-300 border-2 border-primary"
                                    >
                                        Import
                                    </button>
                                )}
                            />

                            <button
                                className="bg-orange-10 text-[#616161] font-semibold py-2 px-8 rounded-md shadow-md active:scale-95 transition-all duration-300 border-2 border-primary"
                                disabled={selectedProduct === undefined}
                                style={{ opacity: selectedProduct === undefined ? 0.4 : 1 }}
                                onClick={handleExport}
                            >
                                Export
                            </button>
                        </div>
                    </div>

                    <Snackbar
                        open={openAlert}
                        autoHideDuration={3000}
                        onClose={() => setOpenAlert(false)}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert
                            severity={snackBarInfo?.isError ? 'error' : 'success'}
                            sx={{ width: '100%' }}
                            onClose={() => setOpenAlert(false)}
                        >
                            {snackBarInfo?.title}
                        </Alert>
                    </Snackbar>
                </div>
            </Container>
        </div>
    )
}

export default ImportExport

import { useState } from 'react'
import { getCurrencySymbol, SuperPlanConversion } from '@/utils/utility_functions'
import { Input } from '@/@ui-lib/atoms'
import { PriceTier } from '@/api/models/model_product_detail'
interface PlanConfigTableProps {
    planConfig: SuperPlanConversion[keyof SuperPlanConversion]
    billingPeriod: string
    onValueChanged: (key: string, value: string) => void
    onDelete?: (key: string) => void
}

export default function PlanConfigTable({ planConfig, onValueChanged, billingPeriod, onDelete }: PlanConfigTableProps) {
    const [showTiers, setShowTiers] = useState<{ [key: string]: boolean }>({})

    const calculateTieredPrice = (quantity: number, tiered: PriceTier[] | undefined) => {
        if (!tiered) return 0
        for (const tier of tiered) {
            const upTo = tier.up_to === 999999999 ? Infinity : Number(tier.up_to)
            if (quantity <= upTo) {
                return tier.price
            }
        }

        return 0
    }

    return (
        <div className="border rounded-xl pt-2 pb-2 bg-gray-100">
            <table className="min-w-full">
                <thead className="rounded-xl">
                    <tr className="bg-gray-100">
                        <th className="px-4 py-2 text-left text-gray-600">Charge</th>
                        <th className="px-4 py-2 text-left text-gray-600">Quantity</th>
                        <th className="px-4 py-2 text-left text-gray-600">Price</th>
                        {onDelete && <th className="px-4 py-2 text-left text-gray-600"></th>}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(planConfig).map((key) => {
                        if (key === 'base_charge' && planConfig.base_charge) {
                            // Handle base_charge case
                            const baseCharge = planConfig.base_charge
                            const price =
                                billingPeriod === 'monthly'
                                    ? `${getCurrencySymbol(baseCharge.monthly?.currency ?? 'USD')} ${
                                          baseCharge.monthly?.price ?? 'N/A'
                                      } /Monthly`
                                    : `${getCurrencySymbol(baseCharge.annual?.currency ?? 'USD')} ${
                                          baseCharge.annual?.price ?? 'N/A'
                                      } /Monthly`

                            return (
                                <tr key={key} className="border-t bg-white items-center align-middle">
                                    <td className="px-4 py-3">Base Charge</td>
                                    <td className="px-4 py-3">1</td>
                                    <td className="px-4 py-3">{price}</td>
                                </tr>
                            )
                        }

                        if (key === 'onetime' && planConfig.onetime) {
                            // Handle onetime charge case
                            const oneTimeCharge = planConfig.onetime
                            return (
                                <tr key={key} className="border-t bg-white items-center align-middle">
                                    <td className="px-4 py-3">One Time Charge</td>
                                    <td className="px-4 py-3">1</td>
                                    <td className="px-4 py-3">{`${getCurrencySymbol(
                                        oneTimeCharge?.currency ?? 'USD'
                                    )} ${oneTimeCharge?.price ?? 'N/A'}/one-time`}</td>
                                </tr>
                            )
                        }

                        if (key === 'usage_based_charge') {
                            const usageCharges = planConfig.usage_based_charge || {}
                            return Object.keys(usageCharges).map((featureId) => {
                                const feature = usageCharges[featureId]
                                const billingDetails = billingPeriod === 'monthly' ? feature.monthly : feature.annual

                                return (
                                    <tr key={featureId} className="border-t bg-white items-center align-middle">
                                        <td>{feature.slug || 'Usage Charge'}</td>
                                        <td>
                                            <Input
                                                name={''}
                                                label={''}
                                                placeholder={'0'}
                                                field={{
                                                    value: feature.quantity,
                                                    onChange: (e: any) => onValueChanged(featureId, e.target.value),
                                                }}
                                            />
                                        </td>
                                        <td className="px-4 py-3">
                                            {billingDetails?.type === 'flat' ? (
                                                <>
                                                    {billingDetails.currency}
                                                    {billingDetails?.price?.toFixed(2) || 'N/A'} {`/ unit`} /
                                                    {billingPeriod}
                                                </>
                                            ) : (
                                                <>
                                                    <div className="flex gap-2 flex-col items-start justify-center">
                                                        <span>
                                                            {'$ '}
                                                            {calculateTieredPrice(
                                                                feature.quantity,
                                                                billingDetails?.price_tiers
                                                            ).toFixed(2)}{' '}
                                                            {'unit'} / {billingPeriod}
                                                        </span>
                                                        <button
                                                            onClick={() =>
                                                                setShowTiers({ ...showTiers, [feature.slug]: true })
                                                            }
                                                            className="ml-2 text-sm text-blue-500 underline"
                                                        >
                                                            View Tiers
                                                        </button>
                                                    </div>
                                                    {/* {showTiers[feature.slug] && (
                                                        <div className="fixed top-0 left-0 z-[200] w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                                                            <div className="bg-white p-6 rounded-lg shadow-lg w-500">
                                                                <div className="flex items-start justify-between gap-12">
                                                                    <h3 className="text-base font-normal mb-4 text-center text-gray-800">
                                                                        Price Tiers for {feature.slug}
                                                                    </h3>
                                                                    <button
                                                                        onClick={() => setShowTiers({ ...showTiers, [feature.slug]: false })}
                                                                        className="text-xl h-full"
                                                                    >
                                                                        <IoMdClose />
                                                                    </button>
                                                                </div>
                                                                <div className="rounded-lg border border-gray-300">
                                                                    <table className="min-w-full rounded-lg border border-gray-300 divide-y divide-gray-200">
                                                                        <thead>
                                                                            <tr className="bg-gray-100">
                                                                                <th className="px-4 py-2 text-left text-gray-600">Range</th>
                                                                                <th className="px-4 py-2 text-left text-gray-600">Price</th>
                                                                                <th className="px-4 py-2 text-left text-gray-600">Currency</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {billingDetails?.price_tiers?.map((tier, index) => {
                                                                                const prevUpTo = index === 0 ? 0 : billingDetails.price_tiers[index - 1].end + 1;
                                                                                const currentUpTo = tier.end === undefined ? "∞" : tier.end;
                                                                                return (
                                                                                    <tr key={index} className="border border-gray-300 hover:bg-gray-50">
                                                                                        <td className="px-4 py-2">{prevUpTo} - {currentUpTo}</td>
                                                                                        <td className="px-4 py-2 font-medium">$ {tier.price_per_unit.toFixed(2)}</td>
                                                                                        <td className="px-4 py-2">{unit}</td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )} */}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        // Optional fallback for unrecognized keys
                        return null
                    })}
                </tbody>
            </table>
        </div>
    )
}

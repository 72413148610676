import { ModelCustomer } from '@/api/models/model_customer'
import { ModelProduct } from '@/api/models/model_product'
import { PriceTier } from '@/api/models/model_product_detail'
import { CustomerAPI } from '@/api/req/customer_api'
import { ProductAPI } from '@/api/req/product_api'
import { ProductDetailAPI } from '@/api/req/product_detail_api'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomerDetail from './widgets/CustomerDetail'
import SubscriptionPreview from './widgets/SubscriptionPreview'
import Spacer from '@/components/common/util/spacer'
import PlanConfigTable from './widgets/PlanConfigTable'
import FlexDatePicker from '@/components/common/date_picker/FlexDatePicker'
import FlexButton from '@/components/common/button/FlexButton'
import {
    SuperAddOnConversion,
    SuperPlanConversion,
    SuperProductConversion,
    superTransformProduct,
    transformSubscription,
    validateEffectValue,
} from '@/utils/utility_functions'
import './customerstyle.css'
import { ModelGuardRail } from '@/api/models/model_guardrail'
import FlexSelect from '@/@ui-lib/atoms/Select/Select'
import AddonConfigTable from './widgets/AddonConfigTable'
import { Input, Loader } from '@/@ui-lib/atoms'
import { SubscriptionAPI } from '@/api/req/subscription_api'

export interface PlanConfig {
    name: string
    quantity: number
    isEditable: boolean
    price: number
    tiered_price?: PriceTier[]
    billingPeriod: string
    type?: string
    unit: string | undefined
    price_id: string
}

export default function CustomerSubscription() {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const customerId = searchParams.get('id')
    const subscriptionId = searchParams.get('subscription_id')
    const currentProductId = searchParams.get('product_id')

    const [customerDetails, setCustomerDetails] = useState<ModelCustomer | undefined>()

    // Error and loading state
    const [error, setError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // Products
    const [products, setProducts] = useState<ModelProduct[] | undefined>()
    const [selectedProductId, setSelectedProductId] = useState<string | undefined>()
    const [guardRails, setGuardRails] = useState<ModelGuardRail[]>([])
    const [selectedProduct, setSelectedProduct] = useState<SuperProductConversion | undefined>()

    // Plan
    const [plans, setPlans] = useState<SuperPlanConversion | undefined>()
    const [selectedPlan, setSelectedPlan] = useState<SuperPlanConversion | undefined>()
    const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>()

    // Addons
    const [addons, setAddons] = useState<SuperAddOnConversion | undefined>()
    const [selectedAddonIds, setSelectedAddonIds] = useState<string[]>([])

    // billing period
    const [billingPeriod, setBillingPeriod] = useState<string>('monthly')

    // start date
    const [startDate, setStartDate] = useState<Date>(new Date(Date.now()))

    // Discounts
    const [fixedDiscount, setFixedDiscount] = useState<number>(0)
    const [recurringDiscount, setRecurringDiscount] = useState<number>(0)
    const [errorFixed, setErrorFixed] = useState<string | undefined>(undefined)
    const [errorRecurring, setErrorRecurring] = useState<string | undefined>(undefined)

    const handleChange = (
        value: string,
        setValue: (val: number) => void,
        effectOn: string,
        setError: (err: string | undefined) => void
    ) => {
        const numberValue = value === '' ? 0 : parseInt(value)
        setValue(numberValue)

        const guardRail = guardRails?.find((item) => item.effect_on === effectOn)
        if (guardRail && !validateEffectValue(numberValue, guardRail.operator, guardRail.effect_value)) {
            setError('Discount exceeds the limit')
        } else {
            setError(undefined)
        }
    }

    // Primary data fetching function
    const fetchInitialData = async () => {
        try {
            setIsLoading(true)
            setError(null)

            // Fetch customer details and products in parallel
            const [customerResponse, productsResponse] = await Promise.all([
                CustomerAPI.getCustomerById(false, customerId!),
                ProductAPI.getAll(false),
            ])

            setCustomerDetails(customerResponse)
            setProducts(productsResponse)

            // If we have subscription and product IDs, initialize product selection
            if (subscriptionId && currentProductId) {
                setSelectedProductId(currentProductId)
                await fetchProductRelatedData(currentProductId)
            }
        } catch (err) {
            setError(err as string)
            console.error('Error fetching initial data:', err)
        } finally {
            setIsLoading(false)
        }
    }

    // Product-related data fetching
    const fetchProductRelatedData = async (productId: string) => {
        try {
            setIsLoading(true)

            // Fetch product details and guard rails in parallel
            const [productDetails, guardRailsData] = await Promise.all([
                ProductDetailAPI.getAll(productId),
                ProductAPI.getProductGuardRail(productId),
            ])

            const transformedProduct = superTransformProduct(productDetails)

            setSelectedProduct(transformedProduct)
            setPlans(transformedProduct.plans)
            setAddons(transformedProduct.addons)
            setGuardRails(guardRailsData)

            // Fetch subscriptions after product details are set

            await fetchCustomerSubscriptions(customerId!)
        } catch (err) {
            setError(err as string)
            console.error('Error fetching product data:', err)
        } finally {
            setIsLoading(false)
        }
    }

    // Subscription data fetching
    const fetchCustomerSubscriptions = async (custId: string) => {
        try {
            const response = await SubscriptionAPI.getCustomerSubscriptions(false, custId)
            if (response) {
                const transformedSubscriptions = transformSubscription(response)
                const currentSubscription = transformedSubscriptions[subscriptionId!]

                if (currentSubscription) {
                    setSelectedPlanId(currentSubscription.planId)
                    setSelectedAddonIds(Object.keys(currentSubscription.addOns ?? {}).map((key) => key))

                    setAddons((prevAddons) => {
                        const updatedAddons = { ...prevAddons }
                        Object.keys(currentSubscription.addOns ?? {}).forEach((key) => {
                            if (updatedAddons[key]) {
                                updatedAddons[key] = {
                                    ...updatedAddons[key],
                                    quantity: currentSubscription.addOns[key].quantity,
                                }
                            }
                        })
                        return updatedAddons // Return the updated state
                    })
                }
            }
        } catch (err) {
            setError(err as string)
            console.error('Error fetching subscriptions:', err)
        }
    }

    // Initial data load
    useEffect(() => {
        if (customerId) {
            fetchInitialData()
        }
    }, [customerId]) // Only depend on customerId

    // Handle product selection
    const handleProductSelection = async (productId: string) => {
        if (!productId) return

        setSelectedProductId(productId)
        await fetchProductRelatedData(productId)
    }

    const handleDelete = (addonId: string) => {
        setSelectedAddonIds(selectedAddonIds.filter((id) => id !== addonId))
    }

    const onCancelClicked = () => {
        navigate(-1)
    }

    const onSubmitClicked = () => {
        setIsLoading(true)
        const baseData = {
            billing_provider: 'internal',
            customer_id: customerId,
            subscription_status: 'ACTIVE',
            billing_period: billingPeriod.toUpperCase(),
            billing_anchor: startDate.toISOString(),
            start_date_time: startDate.toISOString(),
        }

        const payload = {
            ...baseData,
            discount: [
                !errorFixed && {
                    billing_cadence: 'ONETIME',
                    discount: {
                        type: 'percentage',
                        value: parseFloat(fixedDiscount.toString()),
                    },
                },
                !errorRecurring && {
                    billing_cadence: 'RECURRING',
                    discount: {
                        type: 'percentage',
                        value: parseFloat(recurringDiscount.toString()),
                    },
                },
            ],
            add_ons: [
                ...Object.keys(addons ?? {})
                    .filter((key) => selectedAddonIds.includes(key))
                    .map((key) => {
                        const billingDetails = billingPeriod === 'Monthly' ? addons![key].monthly : addons![key].annual
                        return {
                            add_on_id: key,
                            quantity: addons![key].quantity,
                            price_id: billingDetails?.price_id,
                            start_date: startDate.toISOString(),
                        }
                    }),
            ],
            line_items: [
                ...Object.keys(plans![selectedPlanId!])
                    .map((key) => {
                        if (key === 'base_charge' && plans![selectedPlanId!].base_charge) {
                            const billingDetails =
                                billingPeriod === 'monthly'
                                    ? plans![selectedPlanId!][key]?.monthly
                                    : plans![selectedPlanId!][key]?.annual
                            return {
                                type: 'base_charge',
                                product_id: selectedProduct?.id,
                                plan_id: selectedPlanId,
                                plan_version: 'v1',
                                price_id: billingDetails?.price_id,
                                quantity: 1,
                            }
                        } else if (key === 'onetime') {
                            return {
                                type: 'onetime',
                                product_id: selectedProduct?.id,
                                plan_id: selectedPlanId,
                                plan_version: 'v1',
                                price_id: plans![selectedPlanId!][key]?.price_id,
                                quantity: 1,
                            }
                        } else if (key === 'usage_based_charge') {
                            return Object.keys(plans![selectedPlanId!][key] ?? {}).map((featureKey) => {
                                const feature = plans![selectedPlanId!][key]![featureKey]
                                const billingDetails = billingPeriod === 'monthly' ? feature.monthly : feature.annual

                                return {
                                    type: feature.slug,
                                    product_id: selectedProduct?.id,
                                    plan_id: selectedPlanId,
                                    plan_version: 'v1',
                                    price_id: billingDetails?.price_id,
                                    quantity: feature.quantity,
                                }
                            })
                        } else {
                            return null
                        }
                    })
                    .flat()
                    .filter((item) => item !== null),
            ],
        }

        CustomerAPI.createSubscription(false, payload).then((response) => {
            if (response) {
                setIsLoading(false)
                onCancelClicked()
            }
        })
    }

    return (
        <div className="px-5 h-full flex scrollbar-hidden bg-white">
            {isLoading && (
                <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-50 z-50">
                    <Loader />
                </div>
            )}
            {error && (
                <div className="absolute inset-0 flex justify-center items-center">
                    <p className="text-primary">{error}</p>
                </div>
            )}
            <div className="w-[70%] mt-10 overflow-y-auto pr-5 scrollbar-hidden">
                <div className="mb-5 text-2xl font-bold text-primary">
                    <span className="block text-left">Create Subscription</span>
                </div>

                {customerDetails && <CustomerDetail customer={customerDetails!} />}
                <Spacer height={20} />

                {products && (
                    <div className="ml-2">
                        <FlexSelect
                            label="Select Product"
                            name="product"
                            field={{
                                onChange: (value: any) => {
                                    setSelectedProductId(value)
                                    handleProductSelection(value)
                                },
                                value: selectedProductId,
                            }}
                            options={products?.map((product) => ({
                                value: product.name,
                                id: product.id,
                            }))}
                        />
                    </div>
                )}
                <Spacer height={5} />
                {plans && (
                    <div className="ml-2">
                        <FlexSelect
                            label="Select Plan"
                            name="plan"
                            field={{
                                onChange: (value: any) => {
                                    setSelectedPlanId(value)
                                    setSelectedPlan(value)
                                },
                                value: selectedPlanId,
                            }}
                            options={Object.keys(plans ?? {})?.map((key) => ({
                                value: plans[key].name,
                                id: key,
                            }))}
                        />
                    </div>
                )}

                <Spacer height={5} />

                {selectedPlanId && (
                    <div className="ml-2">
                        <FlexSelect
                            label="Select Billing Period"
                            name="billing_period"
                            field={{
                                onChange: (value: any) => {
                                    setBillingPeriod(value)
                                },
                                value: billingPeriod,
                            }}
                            options={[
                                ...(plans && plans[selectedPlanId]?.base_charge?.monthly
                                    ? [{ value: 'Monthly', id: 'monthly' }]
                                    : []),
                                ...(plans && plans[selectedPlanId]?.base_charge?.annual
                                    ? [{ value: 'Annually', id: 'annually' }]
                                    : []),
                            ]}
                        />
                    </div>
                )}
                <Spacer height={5} />
                {selectedPlanId && (
                    <div className="ml-2">
                        <h1 className={'mb-1 text-base font-semibold text-primary'}>Charges</h1>
                        <PlanConfigTable
                            planConfig={plans![selectedPlanId!]}
                            billingPeriod={billingPeriod}
                            onValueChanged={(key, value) => {
                                setPlans((prevPlans) => {
                                    const newPlans = { ...prevPlans }
                                    if (!newPlans[selectedPlanId]?.usage_based_charge?.[key]) return prevPlans // Additional check
                                    const parsedValue = value === '' ? 0 : parseInt(value)

                                    newPlans[selectedPlanId] = {
                                        ...newPlans[selectedPlanId],
                                        usage_based_charge: {
                                            ...newPlans[selectedPlanId].usage_based_charge,
                                            [key]: {
                                                ...newPlans[selectedPlanId].usage_based_charge[key],
                                                quantity: parsedValue,
                                            },
                                        },
                                    }

                                    return newPlans
                                })
                            }}
                        />
                    </div>
                )}

                <Spacer height={10} />

                {selectedPlan && (
                    <div className="ml-2">
                        <FlexSelect
                            label="Select Addon"
                            name="addon"
                            field={{
                                onChange: (value: any) => {
                                    setSelectedAddonIds([...selectedAddonIds, value])
                                },
                            }}
                            options={Object.keys(addons!)?.map((key) => ({
                                value: addons![key].name,
                                id: key,
                            }))}
                        />
                    </div>
                )}

                <Spacer height={5} />
                {selectedAddonIds && selectedAddonIds.length > 0 && (
                    <AddonConfigTable
                        addOnConfig={addons ?? {}}
                        selectedAddonIds={selectedAddonIds}
                        billingPeriod={billingPeriod}
                        onDelete={handleDelete}
                        onValueChanged={(key, value) => {
                            setAddons((prevAddons) => {
                                const newAddons = { ...prevAddons }
                                const parsedValue = value === '' ? 0 : parseInt(value)
                                newAddons[key].quantity = parsedValue ?? 0
                                return newAddons
                            })
                        }}
                    />
                )}

                <Spacer height={20} />

                {selectedPlanId && (
                    <div className="ml-2 w-80">
                        <Input
                            label="Fixed Discount (%)"
                            field={{
                                value: fixedDiscount,
                                onChange: (e: any) =>
                                    handleChange(
                                        e.target.value,
                                        setFixedDiscount,
                                        'cadence_fixed_price',
                                        setErrorFixed
                                    ),
                            }}
                            placeholder="0"
                            name="fixed_discount"
                            errors={{ fixed_discount: errorFixed ? { message: errorFixed } : undefined }}
                        />

                        <Input
                            label="Recurring Discount (%)"
                            field={{
                                value: recurringDiscount,
                                onChange: (e: any) =>
                                    handleChange(
                                        e.target.value,
                                        setRecurringDiscount,
                                        'cadence_recurring_price',
                                        setErrorRecurring
                                    ),
                            }}
                            placeholder="0"
                            name="recurring_discount"
                            errors={{ recurring_discount: errorRecurring ? { message: errorRecurring } : undefined }}
                        />
                    </div>
                )}
                <Spacer height={5} />
                {selectedPlanId && (
                    <div className="ml-2">
                        <FlexDatePicker
                            label="Start Date"
                            onDatePicked={(value) => {
                                setStartDate(value!)
                            }}
                            selectedDate={startDate}
                        />
                    </div>
                )}
                <Spacer height={60} />

                {selectedPlanId && !subscriptionId && (
                    <div className="flex gap-5">
                        <FlexButton type="outlined" onClick={onCancelClicked} label="Cancel" />
                        <FlexButton type="filled" onClick={onSubmitClicked} label="Create" isLoading={isLoading} />
                    </div>
                )}

                <Spacer height={20} />
            </div>

            <div className="w-[30%] h-full flex justify-center items-center">
                {selectedPlanId && (
                    <SubscriptionPreview
                        selectedPlanId={selectedPlanId!}
                        selectedAddonIds={selectedAddonIds}
                        plans={plans!}
                        planName={plans![selectedPlanId!].name}
                        addons={addons!}
                        billingPeriod={billingPeriod}
                        fixedDiscount={errorFixed ? 0 : fixedDiscount}
                        recurringDiscount={errorRecurring ? 0 : recurringDiscount}
                        productName={selectedProduct?.name}
                        startDate={startDate}
                    />
                )}
            </div>
        </div>
    )
}

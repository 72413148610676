import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface DatePickerProps {
    label: string
    selectedDate: Date
    onDatePicked: (date: Date | null) => void
}

export default function FlexDatePicker({ selectedDate, onDatePicked, label }: DatePickerProps) {
    return (
        <div className="items-center gap-5">
            <h1 className={'mb-1 text-base font-semibold text-primary'}>{label}</h1>
            <DatePicker
                selected={selectedDate}
                onChange={onDatePicked}
                className="px-3 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-primary focus:border-priring-primary"
                dateFormat="MMMM d, yyyy"
                wrapperClassName="custom-datepicker"
            />
        </div>
    )
}

import { Box, CircularProgress, Typography } from '@mui/material'

interface FlexButtonProps {
    text?: string
    isLoading?: boolean
    icon?: React.ReactNode
    width?: number
    height?: number
    radius?: number
    disable?: boolean
    textSize?: number
    backgroundColor?: string
    onClick: () => void
}

export default function FlexButton({
    text,
    icon,
    onClick,
    width,
    height,
    isLoading,
    textSize = 18,
    radius = 5,
    disable = false,
    backgroundColor,
}: FlexButtonProps) {
    return (
        <div
            style={{
                cursor: 'pointer',
                pointerEvents: disable ? 'none' : 'auto',
            }}
        >
            <Box
                onClick={onClick}
                className={'flex shadow-md'}
                sx={{
                    borderRadius: radius + 'px',
                    display: 'flex',
                    padding: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    opacity: disable ? 0.5 : 1,
                    backgroundColor: backgroundColor ?? '#2257BE',
                    width: { width },
                    height: { height },
                    userSelect: 'none',
                }}
            >
                {isLoading && <CircularProgress size={20} sx={{ color: 'white' }} />}
                {!isLoading && icon}
                {!isLoading && (
                    <Typography color={'white'} fontWeight={600} fontSize={textSize}>
                        {text}
                    </Typography>
                )}
            </Box>
        </div>
    )
}

import { forwardRef } from 'react'

interface ButtonProps {
    label: string
    isLoading?: boolean
    disabled?: boolean
    prefixIcon?: JSX.Element
    buttonType?: 'filled' | 'outlined'
    onClick?: () => void
    fullWidth?: boolean
    [key: string]: any
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            label,
            isLoading = false,
            disabled = false,
            prefixIcon,
            buttonType = 'filled',
            onClick,
            fullWidth = false,
            ...props
        },
        ref
    ) => {
        return (
            <button
                ref={ref}
                onClick={onClick}
                disabled={disabled || isLoading}
                {...props}
                className={`inline-flex items-center justify-center px-2.5 py-2 rounded-lg text-sm font-medium focus:outline-none
                    ${fullWidth ? 'w-full' : ''}  
                    ${
                        buttonType === 'filled'
                            ? 'bg-primary text-white'
                            : 'border-2 border-pribg-primary text-pribg-primary'
                    } 
                    ${buttonType === 'outlined' ? 'bg-white' : ''} 
                    ${
                        !disabled && !isLoading
                            ? buttonType === 'outlined'
                                ? 'hover:bg-orange-100'
                                : 'hover:bg-primary'
                            : ''
                    } 
                    ${disabled || isLoading ? 'bg-orange-200 text-pribg-primary cursor-not-allowed' : ''}
                `}
            >
                {isLoading ? (
                    <span className="loader animate-spin border-2 border-t-2 border-pribg-primary border-t-white rounded-full w-5 h-5 mr-2"></span>
                ) : (
                    prefixIcon && <span className="mr-2">{prefixIcon}</span>
                )}
                {!isLoading && <span className="flex-1 text-center">{label}</span>}
            </button>
        )
    }
)

Button.displayName = 'Button'

export default Button

import { FeatureMapping } from '../../api/models/model_product_detail'
import { showFeatureDetail } from '../../utils/helper_functions_components'
import checkIcon from '@/assets/png/check.png'

interface QuotationCardProps {
    feature: FeatureMapping
}
export default function QuotationFeatureCard({ feature }: QuotationCardProps) {
    return (
        <div className=" flex gap-1 items-center  px-2 py-2.5 text-base leading-6 rounded  bg-opacity-10 ">
            <span>
                <img src={checkIcon} className="size-5 bg-cover" alt="check icon" />
            </span>
            <span className="text-[#616161] text-base font-[300]">{showFeatureDetail(feature)}</span>
        </div>
    )
}

import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import React from 'react'
import AuthMiddleWare from './middlewares/auth_middleware'
import Auth from './pages/auth/auth'
import Home from './pages/home/home'
import Customer from './pages/customer/CustomerPage.tsx'
import DefaultRoute from './middlewares/default_route'
import SalesPageNew from './pages/sales/QuotationPage.tsx'
import ImportExport from './pages/import_export/import_export.tsx'
import { UserProvider } from './context/UserContext.tsx'
import NotAuthorized from './pages/auth/NotAuthorized.tsx'
import Components from './pages/components/components.tsx'
import AddonPage from './pages/addon/AddOnPage.tsx'
import AddonsEditPage from './pages/addon/AddOnEditPage.tsx'
import PlansPage from './pages/plans/PlansPage.tsx'
import PricePage from './pages/price/price_page.tsx'
import PlansEditPage from './pages/plans/PlansEditPage.tsx'
import CustomerSubscription from './pages/customer/CustomerSubscription.tsx'
import CustomerDetailPage from './pages/customer/CustomerDetailPage.tsx'
import FormPage from './FormPage.tsx'
import MeterPage from './pages/meter/MeterPage.tsx'

const routers = createBrowserRouter([
    {
        path: '/login',
        element: <Auth />,
    },
    {
        path: '/components',
        element: <Components />,
    },

    {
        path: '/',
        element: (
            <AuthMiddleWare requiredRole={['admin', 'sales']}>
                <Home />
            </AuthMiddleWare>
        ),
        children: [
            {
                path: '/',
                element: <DefaultRoute />,
            },
            {
                path: '/form',
                element: <FormPage />,
            },
            {
                path: '/price',
                element: (
                    <AuthMiddleWare requiredRole={['admin', 'sales']}>
                        <PricePage />
                    </AuthMiddleWare>
                ),
            },
            {
                path: '/price/plan',
                element: (
                    <AuthMiddleWare requiredRole={['admin', 'sales']}>
                        <PlansEditPage />
                    </AuthMiddleWare>
                ),
            },
            {
                path: '/plans',
                element: (
                    <AuthMiddleWare requiredRole={['admin', 'sales']}>
                        <PlansPage />
                    </AuthMiddleWare>
                ),
            },
            {
                path: '/plans/edit',
                element: (
                    <AuthMiddleWare requiredRole={['admin', 'sales']}>
                        <PlansEditPage />
                    </AuthMiddleWare>
                ),
            },
            {
                path: '/addon',
                element: (
                    <AuthMiddleWare requiredRole={['admin', 'sales']}>
                        <AddonPage />
                    </AuthMiddleWare>
                ),
            },
            {
                path: '/addon/edit',
                element: (
                    <AuthMiddleWare requiredRole={['admin', 'sales']}>
                        <AddonsEditPage />
                    </AuthMiddleWare>
                ),
            },
            {
                path: 'quotes',
                element: (
                    <AuthMiddleWare requiredRole={['admin', 'sales']}>
                        <SalesPageNew />
                    </AuthMiddleWare>
                ),
            },
            {
                path: 'customers',
                element: (
                    <AuthMiddleWare requiredRole={['admin', 'sales']}>
                        <Customer />
                    </AuthMiddleWare>
                ),
            },
            {
                path: 'customers/subscription',
                element: (
                    <AuthMiddleWare requiredRole={['admin', 'sales']}>
                        <CustomerSubscription />
                    </AuthMiddleWare>
                ),
            },
            {
                path: 'customers/detail',
                element: (
                    <AuthMiddleWare requiredRole={['admin', 'sales']}>
                        <CustomerDetailPage />
                    </AuthMiddleWare>
                ),
            },
            {
                path: 'import-export',
                element: (
                    <AuthMiddleWare requiredRole={['admin']}>
                        <ImportExport />
                    </AuthMiddleWare>
                ),
            },
            {
                path: '/metering',
                element: (
                    <AuthMiddleWare requiredRole={['admin', 'sales']}>
                        <MeterPage />
                    </AuthMiddleWare>
                ),
            },
        ],
    },
    {
        path: '/not-authorized',
        element: <NotAuthorized />,
    },
])

function App() {
    return (
        <React.StrictMode>
            <UserProvider>
                <RouterProvider router={routers}></RouterProvider>
            </UserProvider>
        </React.StrictMode>
    )
}

export default App

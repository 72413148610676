import { forwardRef } from 'react'

interface Column {
    key: string
    header: string
}

interface Data {
    [key: string]: string | number
}

interface TableProps {
    columns: Column[]
    data: Data[]
    onRowClick?: (row: Data) => void
}

const FlexTable = forwardRef<HTMLDivElement, TableProps>(({ columns, data, onRowClick }: TableProps, ref) => {
    return (
        <div ref={ref} className="overflow-x-auto">
            <div className="border border-gray-300 rounded-lg p-2">
                <table className="table-auto w-full">
                    <thead className="border-b border-gray-300">
                        <tr>
                            {columns.map((column) => (
                                <th key={column.key} className="px-4 py-2 text-left text-primary">
                                    {column.header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex} onClick={() => onRowClick && onRowClick(row)}>
                                {columns.map((column) => (
                                    <td
                                        key={column.key}
                                        className={`px-4 py-2 ${
                                            rowIndex !== data.length - 1 ? 'border-b border-gray-300' : ''
                                        } ${onRowClick ? 'cursor-pointer hover:bg-gray-50' : ''}`}
                                    >
                                        {row[column.key]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
})

FlexTable.displayName = 'FlexTable'

export default FlexTable

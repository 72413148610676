import { useEffect, useRef, useState } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import Chip from '@/components/common/chip/chip'
import { CircularProgress, Container } from '@mui/material'
import FlexColors from '@/utils/colors.ts'
import '../addon/index.css'
import { Button, showCustomToast } from '@/@ui-lib/atoms'
import { ToastStatus } from '@/utils/enums'
import MeterDrawer from './MeterPageDrawer'
import { MeterAPI } from '@/api/req/meter_api'
import { ModelMeter } from '@/api/models/model_meter'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { LuMoveVertical } from 'react-icons/lu'
import { Plus } from 'lucide-react'

export interface PriceTierV2 {
    from: number | string
    to: number | string
    price: number
}

function formatDate(timestamp: string) {
    const date = new Date(timestamp)
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    })
}

const PlansPage = () => {
    const gridRef = useRef<AgGridReact>(null)

    const [loading, setLoading] = useState(true)

    const [openPageDrawer, setOpenPageDrawer] = useState(false)
    const [meters, setMeters] = useState<ModelMeter[]>([])

    const ActionCellRenderer: React.FC = (props: any) => {
        return (
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <LuMoveVertical className={'text-xl '} />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem
                        onClick={() => {
                            deleteMeter(props.data?.id)
                        }}
                    >
                        Delete
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        )
    }
    useEffect(() => {
        getAllMeters()
    }, [])

    const getAllMeters = () => {
        setLoading(true)
        MeterAPI.getAll(false).then((value) => {
            console.log('Value', value)
            setMeters(value)
        })
        setLoading(false)
    }

    const deleteMeter = (id: string) => {
        MeterAPI.deleteMeter(false, id).then((value) => {
            console.log('Value', value)
            getAllMeters()
        })
    }

    const colDefs: ColDef[] = [
        {
            headerName: 'Name',
            field: 'event_name',
            // filter: true,
            sortable: true,
            flex: 2,
            cellStyle: { cursor: 'pointer' },
        },
        {
            headerName: 'Aggregation Type',
            field: 'aggregation.type',
            // filter: true,
            sortable: true,
            flex: 2,
            cellStyle: { cursor: 'pointer' },
        },
        {
            headerName: 'Status',
            field: 'status',
            cellRenderer: (params: any) => (
                <div className="flex justify-start w-full items-center h-full ">
                    <Chip isActive={params.value === 'active'} />
                </div>
            ),
            // filter: true,
            sortable: true,
            // width: 100,
            flex: 2,
            cellStyle: { cursor: 'pointer' },
        },
        {
            headerName: 'Created At',
            field: 'created_at',
            valueGetter: (s: any) => formatDate(s.data.created_at),
            // filter: true,
            sortable: true,
            flex: 2,
            cellStyle: { cursor: 'pointer' },
        },
        {
            headerName: '',
            field: 'actions',
            cellRenderer: ActionCellRenderer,
            width: 60,
            pinned: 'right',
            cellStyle: { cursor: 'pointer' },
        },
    ]

    const onGridReady = (params: any) => {
        gridRef.current!.api.sizeColumnsToFit()
        // Auto-size the Actions column to fit its content
        params.columnApi.autoSizeColumn('actions')
    }

    return (
        <div className=" min-h-screen w-full bg-white">
            <Container>
                {/* <div className="p-4 flex bg-white card  justify-between items-center mt-8">
                    <h1 className="text-3xl font-bold">Meters</h1>
                    <Button label="Add Meter" prefixIcon={<Plus />} onClick={() => setOpenPageDrawer(true)} />
                </div> */}

                {/* TODO: Remove this after demo  */}
                {/* demo header  */}

                <div className="h-8"></div>
                <div className="w-full flex justify-between items-center">
                    <h1 className="font-semibold text-[24px] bg-white ">Meters</h1>
                    <Button label="Add Meter" prefixIcon={<Plus />} onClick={() => setOpenPageDrawer(true)} />
                </div>
                <div className="w-full border mt-2"></div>

                <div className="ag-theme-quartz mt-12 max-h-min  w-full  bg-white">
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={colDefs}
                        rowData={meters}
                        domLayout="autoHeight"
                        loading={loading}
                        defaultColDef={{
                            cellStyle: {
                                fontSize: '16px',
                                fontWeight: '400',
                            },
                        }}
                        gridOptions={{
                            headerHeight: 35,
                            domLayout: 'autoHeight',
                        }}
                        loadingOverlayComponent={() => (
                            <CircularProgress size={50} style={{ color: FlexColors.primary }} />
                        )}
                        onGridReady={onGridReady}
                    />
                </div>
                <MeterDrawer
                    onSuccess={(data: any) => {
                        showCustomToast(data.message, ToastStatus.SUCCESS)
                        setOpenPageDrawer(false)
                        getAllMeters()
                    }}
                    onError={(data) => {
                        showCustomToast(data.message, ToastStatus.ERROR)
                    }}
                    onClose={() => setOpenPageDrawer(false)}
                    drawerVisibility={openPageDrawer}
                />
            </Container>
        </div>
    )
}

export default PlansPage

import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { HomePageContext } from '../../../pages/home/home'

export default function UserProfile() {
    const { user } = useContext(HomePageContext)

    return (
        // <Box
        //     sx={{
        //         display: 'flex',
        //         justifyContent: 'center',
        //         alignItems: 'center',
        //     }}
        // >
        //     <Box
        //         sx={{
        //             backgroundColor: 'blue',
        //             borderRadius: '10px',
        //             padding: '10px',
        //             width: '40px',
        //             height: '40px',
        //             display: 'flex',
        //             justifyContent: 'center',
        //             alignItems: 'center',
        //         }}
        //     >
        //         <Typography
        //             sx={{
        //                 fontSize: '20px',
        //                 color: 'white',
        //                 fontStyle: 'bold',
        //             }}
        //         >

        //         </Typography>
        //         <span>
        //             {user?.email?.toUpperCase()[0]}
        //         </span>
        //     </Box>
        //     {/* <Typography
        //         variant="h4"
        //         sx={{
        //             fontSize: '18px',
        //             color: 'black',
        //             marginLeft: '10px',
        //         }}
        //     >
        //         {user?.email}
        //     </Typography> */}
        // </Box>
        <span className=" rounded-full text-white flex justify-center items-center text-lg font-semibold cursor-pointer  size-6">
            {/* {user?.email?.toUpperCase()[0]} */}
            <img className="bg-contain" alt="User Profile" src={'https://avatar.iran.liara.run/public'} />
        </span>
    )
}

import QuotationCard from './QuotationCard' // Adjust the import path if needed
import { ModelQuotation } from '@/api/models/model_quotation.ts'

interface QuotationsProps {
    quotations: ModelQuotation[]
    onPlanSelected: (index: number) => void
}

export default function Quotations({ quotations, onPlanSelected }: QuotationsProps) {
    return (
        <div className=" max-w-full overflow-x-scroll flex gap-8">
            {quotations &&
                quotations.length > 0 &&
                quotations.map((quotation, index) => (
                    <QuotationCard
                        key={index}
                        quotation={quotation}
                        isSelected={quotation.selected}
                        onPlanSelected={() => onPlanSelected(index)}
                    />
                ))}
        </div>
    )
}

import { useLocation, useNavigate } from 'react-router-dom'
import supabase from '../../../utils/supabase'
import { ConfigProvider, Menu, Tooltip } from 'antd'
import {  RiDoubleQuotesR, RiSpeedUpLine, RiUploadCloud2Line } from 'react-icons/ri'
import { HiOutlineExternalLink } from 'react-icons/hi'
import { GrCatalogOption, GrTest } from 'react-icons/gr'
import { SiSimpleanalytics, SiVitest } from 'react-icons/si'
import { MdOutlineInsights } from 'react-icons/md'
import React, { useEffect, useState } from 'react'
import { FaAnglesLeft, FaAnglesRight } from 'react-icons/fa6'
import { IoIosLogOut } from 'react-icons/io'
import { useUser } from '@/context/UserContext'
import { IoDocument } from 'react-icons/io5'
import UserProfile from '../user_profile/user_profile'
import { Divider } from '@mui/material'
import { LuUsers } from 'react-icons/lu'

const SideNavBar: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [selectedKey, setSelectedKey] = useState(location.pathname)
    const [collapsed, setCollapsed] = useState(false)
    const userContext = useUser()
    const { user } = userContext

    useEffect(() => {
        setSelectedKey(location.pathname)
    }, [location.pathname])

    const menuItems = [
        {
            // icon: <DollarSign className={'text-xl size-6'} />,
            icon: <GrCatalogOption className={'text-xl size-6'} />,
            key: '/price',
            label: 'Pricing Page',
            roles: ['sales', 'admin'],
            onClick: () => navigate('/price'),
            highlighticon: '/assets/png/plan-highlighted.png',
            unhighlighticon: '/assets/png/plan-unhighlighted.png',
        },
        {
            key: '/customers',
            label: 'Customers',
            roles: ['admin'],
            icon: <LuUsers className={'text-xl size-6'} />,
            onClick: () => navigate('/customers'),
        },
        {
            icon: <RiSpeedUpLine className={'text-xl size-6'} />,
            key: '/metering',
            label: 'Meters',
            roles: ['sales', 'admin'],
            onClick: () => navigate('/metering'),
        },
        // {
        //     icon: <GrCatalogOption className={'text-xl size-6'} />,
        //     key: '/plans',
        //     label: 'Plans',
        //     roles: ['sales', 'admin'],
        //     onClick: () => navigate('/plans'),
        // },
        // {
        //     key: '/addon',
        //     label: 'Add-ons',
        //     roles: ['sales', 'admin'],
        //     icon: <FilePlus className={'text-xl size-6'} />,
        //     onClick: () => navigate('/addon'),
        // },
        {
            key: '/quotes',
            label: 'Quotation',
            roles: ['sales', 'admin'],
            icon: <RiDoubleQuotesR className={'text-xl size-6'} />,
            onClick: () => navigate('/quotes'),
            highlighticon: '/assets/png/quote-highlighted.png',
            unhighlighticon: '/assets/png/quote.png',
        },

        {
            key: '/import-export',
            label: 'Import-Export',
            roles: ['admin'],
            icon: <RiUploadCloud2Line className={'text-xl size-6'} />,
            onClick: () => navigate('/import-export'),
        },

        {
            key: '1',
            label: (
                <span>
                    Analytics <span className="italic text-[0.65rem] text-opacity-15 opacity-50"></span>
                </span>
            ),
            roles: ['sales', 'admin'],
            icon: <SiSimpleanalytics className={'text-lg ml-1 size-4 mr-1'} />,
            disabled: true,
        },
        {
            key: '3',
            label: (
                <span>
                    Experiments
                    <span className="italic text-[0.65rem] text-opacity-15 opacity-50"></span>
                </span>
            ),
            roles: ['sales', 'admin'],
            icon: <GrTest className={'text-xl size-6'} />,
            disabled: true,
        },
        {
            key: '5',
            label: (
                <span>
                    Integrations
                    <span className="italic text-[0.65rem] text-opacity-15 opacity-50"></span>
                </span>
            ),
            roles: ['sales', 'admin'],
            icon: <SiVitest className={'text-xl size-6'} />,
            disabled: true,
        },
        {
            key: '6',
            label: (
                <span>
                    AI Insights
                    <span className="italic text-[0.65rem] text-opacity-15 opacity-50"></span>
                </span>
            ),
            roles: ['sales', 'admin'],
            icon: <MdOutlineInsights className={'text-xl size-6'} />,
            disabled: true,
        },
    ]

    return (
        <aside id="sidebar" className="z-50 h-screen hidden md:block shadow-md  ">
            <nav className="h-screen max-w-64 flex flex-col border-r  transition-all duration-300 ease-in-out">
                <div
                    className={`w-full max-h-[4rem] h-[4rem] p-2 pb-2 flex gap-2 ${
                        collapsed ? 'justify-center' : 'justify-between'
                    } items-center`}
                >
                    <img
                        src="/assets/logo/logo.png"
                        alt="FlexPrice Logo"
                        className={`h-6 ml-5 bg-contain ${collapsed ? 'hidden' : ''}`}
                    />
                    <button
                        className={'text-xl p-2'}
                        onClick={() => {
                            setCollapsed((prev) => !prev)
                        }}
                    >
                        {collapsed ? (
                            <FaAnglesRight className="text-[#71717a] " />
                        ) : (
                            <FaAnglesLeft className="text-[#71717a]  " />
                        )}
                    </button>
                </div>

                <ul
                    className={`flex-1 border-r overflow-y-auto transition-all mt-3 duration-300 ease-in-out ${
                        collapsed ? 'w-20' : 'w-64'
                    }`}
                >
                    <ConfigProvider
                        theme={{
                            components: {
                                Menu: {},
                            },
                            token: {
                                colorPrimary: '#2257BE',
                                colorText: '#8A8A8A',
                            },
                        }}
                    >
                        <Menu
                            selectedKeys={[selectedKey]}
                            mode="inline"
                            items={menuItems
                                .filter((item) => {
                                    if (item.roles) {
                                        return item.roles.includes(userContext.user.role)
                                    }
                                    return true
                                })
                                .map((item) => {
                                    return {
                                        ...item,
                                        icon: collapsed ? (
                                            <Tooltip placement="right" title={item.label}>
                                                {item.highlighticon ? (
                                                    <img
                                                        src={
                                                            selectedKey === item.key
                                                                ? item.highlighticon
                                                                : item.unhighlighticon
                                                        }
                                                        alt="icon"
                                                        className="w-6 h-6"
                                                    />
                                                ) : (
                                                    item.icon
                                                )}
                                            </Tooltip>
                                        ) : (
                                            <>
                                                {item.highlighticon ? (
                                                    <img
                                                        src={
                                                            selectedKey === item.key
                                                                ? item.highlighticon
                                                                : item.unhighlighticon
                                                        }
                                                        alt="icon"
                                                        className="w-6 h-6 mr-2"
                                                    />
                                                ) : (
                                                    <span className="mr-2">{item.icon}</span>
                                                )}
                                            </>
                                        ),
                                        label: collapsed ? null : (
                                            <span className={'font-semibold select-none '}>{item.label}</span>
                                        ),
                                    }
                                })}
                            onClick={(e) => {
                                setSelectedKey(e.key)
                                navigate(e.key)
                            }}
                            style={{ maxWidth: '16rem' }}
                        />
                    </ConfigProvider>
                </ul>

                <div className={'p-2 '}>
                    <a
                        href="https://documenter.getpostman.com/view/35977379/2sAY4xBNAS"
                        target="_blank"
                        rel="noreferrer"
                        className={
                            'w-full p-2 px-4 font-inter gap-2 text-gray-500 rounded-md hover:bg-gray-200 flex items-center active:scale-95 transition-all duration-300 font-medium'
                        }
                    >
                        {collapsed ? (
                            <Tooltip placement="right" title={'Documentation'}>
                                <IoDocument className={'text-[14px]'} />
                            </Tooltip>
                        ) : (
                            <>
                                <IoDocument className={'text-[14px]'} />
                                <p className="ml-0 text-[#8A8A8A] text-[14px]">Documentation</p>
                                <span className="ml-auto">
                                    <HiOutlineExternalLink className={'text-2xl'} />
                                </span>
                            </>
                        )}
                    </a>

                    <button
                        onClick={() => {
                            supabase.auth.signOut()
                            navigate('/login')
                        }}
                        className={
                            'w-full p-2 px-4 font-inter gap-2 text-gray-500 rounded-md hover:bg-gray-200 flex items-center active:scale-95 transition-all duration-300 font-medium'
                        }
                    >
                        {collapsed ? (
                            <Tooltip placement="right" title={'Logout'}>
                                <IoIosLogOut className={'text-[14px]'} />
                            </Tooltip>
                        ) : (
                            <IoIosLogOut className={'text-[14px]'} />
                        )}
                        {collapsed ? '' : <span className="ml-0 text-[14px] text-[#8A8A8A]">Logout</span>}
                    </button>
                    <div className="h-2"></div>
                    <Divider />

                    <div
                        className={
                            'w-full max-w-full p-2 px-4 font-inter gap-2 mt-2 text-gray-500 rounded-md    flex items-center select-none font-medium'
                        }
                    >
                        {collapsed ? (
                            <Tooltip placement="right" title={'Logout'}>
                                <UserProfile />
                            </Tooltip>
                        ) : (
                            <div className="w-full flex justify-between items-center gap-2">
                                <span className="w-full flex gap-2 items-center justify-start">
                                    <UserProfile />
                                    <div className="ml-0 text-[#8A8A8A]">
                                        <p className="text-clip text-wrap text-[12px]">{user.user_name}</p>
                                        {/* <p className="text-clip text-wrap text-[12px]">{user.email}</p> */}
                                    </div>
                                </span>
                                {/* <button>
                                    <FaChevronUp />
                                </button> */}
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        </aside>
    )
}

export default SideNavBar

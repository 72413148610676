import React, { useState, useRef, useEffect } from 'react'
import './index.css'
interface Message {
    user: 'customer' | 'bot'
    text: string
}

const ChatWindow: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([])
    const [input, setInput] = useState<string>('')
    const [isTyping, setIsTyping] = useState<boolean>(false)
    const messagesEndRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [messages])

    useEffect(() => {
        setMessages([{ user: 'bot', text: 'Welcome! How can I assist you today?' }])
    }, [])

    const handleSendMessage = (): void => {
        if (input.trim()) {
            setMessages((prevMessages) => [...prevMessages, { user: 'customer', text: input }])
            setInput('')

            setIsTyping(true)
            setTimeout(() => {
                setIsTyping(false)
                setMessages((prevMessages) => [...prevMessages, { user: 'bot', text: 'Thank you for your message!' }])
            }, 2000)
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            handleSendMessage()
        }
    }

    return (
        <div className="scrollbar-none fixed bottom-20 right-5 w-80 h-96 bg-white rounded-lg shadow-lg flex flex-col">
            <div className="bg-primary text-white text-center py-3 font-bold rounded-t-lg">Chat with Us</div>

            <div className="flex-1 overflow-y-auto scrollbar-none p-4">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`flex ${msg.user === 'customer' ? 'justify-end' : 'justify-start'} my-2`}
                    >
                        <div
                            className={`px-4 py-2 rounded-lg text-sm ${
                                msg.user === 'customer' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-800'
                            }`}
                        >
                            {msg.text}
                        </div>
                    </div>
                ))}

                {isTyping && (
                    <div className="flex justify-start my-2">
                        <div className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg text-sm">
                            <span className="animate-pulse">Typing...</span>
                        </div>
                    </div>
                )}

                <div ref={messagesEndRef} />
            </div>

            <div className="border-t border-gray-200 p-3 flex items-center">
                <input
                    type="text"
                    className="flex-1 border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-orange-400"
                    placeholder="Type your message..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button
                    className="ml-3 px-4 py-2 bg-primary text-white rounded-md text-sm hover:bg-primary"
                    onClick={handleSendMessage}
                >
                    Send
                </button>
            </div>
        </div>
    )
}

export default ChatWindow

import { ModelCustomer } from '@/api/models/model_customer'
import { Drawer } from 'antd'
import { FormBuilder } from '@/@ui-lib/molecules'
import { MeterAPI } from '@/api/req/meter_api'
import React from 'react'

interface MeterDrawerProps {
    onSuccess: (value: any) => void
    onError: (value: any) => void
    onClose: () => void
    customer?: ModelCustomer
    drawerVisibility: boolean
}

export default function MeterDrawer({ onSuccess, onError, onClose, customer, drawerVisibility }: MeterDrawerProps) {
    const formFields = [
        {
            name: 'name',
            label: 'Event name',
            type: 'text',
            placeholder: 'Enter event name',
            validation: {
                required: 'event name required',
            },
        },
        {
            name: 'aggregation',
            label: 'Aggregation',
            type: 'select',
            placeholder: 'Select aggregation',
            validation: {
                required: 'Aggregation must be selected',
            },
            options: [
                { id: 'SUM', value: 'Sum' },
                { id: 'COUNT', value: 'Count' },
            ],
        },
        {
            name: 'aggregation_field',
            label: 'Aggregation field',
            type: 'text',
            placeholder: 'Enter aggregation field',
        },
    ]

    const formRef = React.useRef<any>(null)

    const submitForm = async (data: Record<string, any>) => {
        const payload = {
            aggregation: {
                field: data.aggregation_field,
                type: data.aggregation,
            },
            event_name: data.name,
        }
        console.log('Payload', payload)
        await MeterAPI.createMeter(false, payload).then((result) => {
            if (result) {
                onSuccess({ message: 'Meter created successfully' })
                formRef.current?.reset()
            } else {
                onError({ message: 'Failed to create plan' })
            }
        })
    }
    return (
        <Drawer title={customer ? 'Edit Meter' : 'Add Meter'} onClose={onClose} open={drawerVisibility}>
            <FormBuilder fields={formFields} onSubmit={submitForm} ref={formRef} />
        </Drawer>
    )
}

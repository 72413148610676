import { forwardRef } from 'react'

interface InputProps {
    name: string
    label: string
    placeholder: string
    field: any
    errors?: any
    disabled?: boolean
    required?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ label, name, placeholder, field, disabled = false, errors = {}, required = false }, ref) => {
        const errorMessage = errors[name]?.message
        console.log(errors)
        return (
            <div className="mb-4">
                <label htmlFor={label} className="block text-base font-semibold text-primary mb-1">
                    {label}
                    {required && <span className="text-primary ml-1">*</span>} {/* Show asterisk if required */}
                </label>
                <input
                    {...field}
                    id={label}
                    placeholder={placeholder}
                    disabled={disabled}
                    ref={ref}
                    className={`block w-full px-3 py-2 border ${
                        errorMessage ? 'border-pritext-primary' : 'border-gray-300'
                    } rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-pritext-primary focus:border-pritext-primary sm:text-sm ${
                        disabled ? 'bg-gray-100 cursor-not-allowed' : ''
                    }`}
                />
                {errorMessage && <span className="text-primary text-sm mt-1">{errorMessage}</span>}
            </div>
        )
    }
)

Input.displayName = 'Input'

export default Input
